export function altGet(map, key, alt) {
    if (!map) return null;
    if (key in map) return map[key];
    return alt;
}

export function itemWithKeyFrom(list, key, value) {
    for (var i = 0; i !== list.length; i++) {
        let item = list[i];
        if (item[key] === value) {
            return item;
        }
    }
    return null;
}