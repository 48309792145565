// React
import React, { Component } from 'react';

// Material UI
import { FormControl, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';

// APIs
import { editIntroRequestWith, introRequestBlurb, partnersFor } from '../../api';

// UIs
import styles from './styles/Intro.module.scss';
import { ButtonLarge, ButtonSmall } from '../base';
import { PartnerCreator } from '../partner';

const PartnerAutocomplete = withStyles({
    root: {
        '& label.Mui-focused': {
          color: '#AAAAAA',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#AFAFAF',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#AAAAAA',
          },
        },
      }
})(Select);

export class IntroModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creatingPartner: false,
            partners: [],
            partner: "",
            step: 0
        };
    }
    
    componentDidMount() {
        this.loadData();
    }

    closeIntro = async () => {
        let { prospect, requester } = this.props.data;
        let { partner } = this.state;
        let success = await editIntroRequestWith(prospect, partner.id, 1);
        if (success) {
            this.props.reloadData();
            this.props.close();
        }
    }
    
    createIntro = async () => {
        if (!this.state.partner && !this.props.data.angel) return;
        let { funding_round, company_id } = this.props.data;
        let connection = (await introRequestBlurb(
            funding_round,
            this.state.partner.id,
            company_id
        ));
        window.open("mailto:" + connection.email + "?body=" + encodeURIComponent(connection.message) + "&subject=" + encodeURIComponent(connection.subject), "_blank");
        this.setState({
            step: 1
        });
    }
    
    createPartner= () => {
        this.setState({creatingPartner: true});
    }
    
    loadData = async () => {
        let partners = (await partnersFor(this.props.data.investor_id, true)) || [];
        this.setState({ partners: partners });
    }

    selectPartner = (e) => {
        this.setState({ partner: e.target.value });
    }

    useCreated = async (partner)  => {
        this.setState({ creatingPartner: false, partner: partner });
        if (partner) this.createIntro();
    }
    
    render() {
        return (
            <div className={styles.intro}>
                { this.state.step === 0 ? (
                    <div>
                        <h1 className={styles.title}>
                            Which partner at <span className={styles.investor}>{ this.props.data.investor }</span> would you like to introduce <br/>
                            <span className={styles.company}>{ this.props.data.company }</span> to?
                        </h1>
                        { !this.state.creatingPartner ? (
                            <div className={styles.inputs}>
                                <FormControl className={styles.partners} variant="outlined">
                                    <InputLabel id="partner-label">Partner</InputLabel>
                                    <PartnerAutocomplete
                                        labelId="partner-label"
                                        id="partner"
                                        value={this.state.partner}
                                        onChange={this.selectPartner}
                                        label="Partner"
                                        placeholder="Partner"
                                        renderValue={x => x.full_name}
                                    >
                                        {
                                            this.state.partners.map(x => (
                                                <MenuItem data={x} key={x.id} value={x}>
                                                    <StrengthIndicator strength={x.match_strength.total} />{x.full_name}<h3 className={styles.role}>{x.role}</h3>
                                                </MenuItem>
                                            ))
                                        }
                                    </PartnerAutocomplete>
                                </FormControl>
                                <h3 className={styles.intermediateLabel} >or</h3>
                                <ButtonSmall color="yellow" onClick={this.createPartner} title="Create New Partner" />
                            </div>
                        ) : (
                            <PartnerCreator close={this.useCreated} firm={this.props.data.investor_id} />
                        )
                        }
                    </div>
                ) : (
                    <div>
                        <h1 className={styles.title}>
                            Making an intro to <span className={styles.investor}>{ this.state.partner.first_name }</span> at <span className={styles.investor}>{ this.props.data.investor }</span> for <span className={styles.company}>{ this.props.data.company }</span>
                        </h1>
                    </div>
                )}
                <div className={styles.buttons}>
                    {!this.state.creatingPartner &&
                        (this.state.step === 0 ? (
                            <ButtonLarge color={"yellow"} disabled={this.state.partner === ""} onClick={this.createIntro} title="Create Intro Email" />
                        ) : (
                            <ButtonLarge color="green" onClick={this.closeIntro} title="Mark Intro as Complete" />
                        ))
                    }
                </div>
            </div>
        );
    }
}

class StrengthIndicator extends Component {
    render() {
        let color = styles.red;
        let { strength } = this.props;
        if (strength >= 0.8) {
            color = styles.green;
        } else if (strength >= 0.5) {
            color = styles.yellow;
        }
        return (
            <div className={[styles.strengthIndicator, color].join(' ')} />
        );
    }
}