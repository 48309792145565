/* Imports */
// React
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import { IconButton, Slide, Toolbar, Tooltip, Typography, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';

// APIs
import { editIntroRequestWith, findProspectForFirmWith, fundingRounds, introRequestFor, introRequests, introSettings, prospectStates, saveIntroBlurbAs, saveProspectForFirmWith, searchFor } from '../../api';

// UIs
import { ButtonLarge, ButtonSmall, LoadingView, Modal, NavBar } from '../base';
import { IntroModal } from './intro';
import { colorFor, dateDisplayFrom } from '../formatting';
import styles from './styles/Requests.module.scss';

/* Class Declarations */
export class Requests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: -1,
            intro_requests: [],
            intro_to: null,
            loading: true,
            settings_open: false,
            selectionModel: [],
            errors: []
        };
    }

    // Lifecycle Events
    componentDidMount() {
        document.title = "Orchard - Intro Requests";
        this.loadData();
        this.reloader = setInterval(() => {
            this.loadData();
        }, 10000);
    }

    componentWillUnmount() {
        if (this.reloader) clearInterval(this.reloader); // Remove our short poll
    }

    // Helper Functions
    cancelIntro = () => { this.setState({ intro_to: null }); } // Close the intro modal
    closeSettings = () => { this.setState({ settings_open: false }); } // Close the settings modal
    
    deleteSelected = () => {
        let { intro_requests, selectionModel } = this.state;
        this.setState({
            intro_requests: intro_requests.filter(x => !selectionModel.includes(x.prospect)), // TODO: cancel in-flight requests
            selectionModel: []
        });
        selectionModel.map(async x => await editIntroRequestWith(x, null, -1)); // Delete each intro request in turn
        this.loadData(); // Reload data asynchronously
    }

    loadData = async () => {
        let data = (await introRequests(0)) || [];
        this.setState({
            intro_requests: data.map(x => {
                x["id"] = x.prospect; // Identify each request by prospect
                return x;
            }),
            loading: false
        });
    }
    
    openSettings = () => { this.setState({ settings_open: true }); } // Open the settings modal
    setIntroingTo = (request) => { this.setState({ intro_to: request }); } // Open the intro modal with a request object
    setSelectionModel = (newModel) => { this.setState({ selectionModel: newModel }); } // Select multiple rows
    
    // Render Event
    render() {
        let { intro_requests, selectionModel } = this.state;
        const fields = [
            { field: "company", headerName: "Company", flex: 1, renderCell: (params) => (
                <Link to={"company/" + params.row.company_id}>{params.row.company}</Link>
            ) },
            { field: "investor", headerName: "Investor", flex: 1, renderCell: (params) => (
                <span style={{ alignItems: 'center', display: 'flex' }}>
                    <ScoreIndicator conflict={params.row.has_conflict} isPear={params.row.requester_org === "Pear VC"} score={params.row.score.score} />
                    {params.row.investor}
                </span>
            ) },
            { field: "date_created", headerName: "Date", flex: 0.75, renderCell: (params) => dateDisplayFrom(params.row.date_created) },
            { field: "requester", headerName: "Requester", flex: 1, renderCell: (params) => (params.row.requester_first_name + " " + params.row.requester_last_name)},
            { field: "best_connection", headerName: "Best Intro", flex: 1 },
            {
                field: "actions",
                headerName: "Actions",
                disableClickEventBubbling: true,
                renderCell: (params) => (
                    <RequestActions data={params.row} makeIntro={this.setIntroingTo} reloadData={this.loadData} />
                ),
                flex: 2
            }
        ];
        
        return (
            <div className={styles.layout}>
                <NavBar title="Intro Requests">
                    <ButtonLarge color="yellow" onClick={this.openSettings} title="Edit My Intro Settings" />
                </NavBar>
                <div className={styles.container}>
                    { !this.state.loading ? (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Toolbar className={styles.toolbar}>
                                {selectionModel.length > 0 ? (
                                    <Typography className={styles.title} color="inherit" variant="subtitle1" component="div">
                                        {selectionModel.length} selected
                                    </Typography>
                                ) : (
                                    <CreateRequestGroup
                                        makeIntro={this.setIntroingTo}
                                        reload={this.loadData}
                                    />
                                )}
                                {selectionModel.length > 0 && (
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="delete" onClick={this.deleteSelected}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Toolbar>
                            <DataGrid
                                rows={intro_requests}
                                columns={fields}
                                onSelectionModelChange={(newSelection) => {
                                    this.setSelectionModel(newSelection.selectionModel);
                                }}
                                selectionModel={selectionModel}
                                checkboxSelection
                                disableSelectionOnClick
                            />
                        </div>
                    ) : (
                        <LoadingView />
                    )}
                </div>
                <Modal closeModal={this.cancelIntro} visible={this.state.intro_to}>
                    <IntroModal close={this.cancelIntro} data={this.state.intro_to} reloadData={this.loadData} />
                </Modal>
                <Modal closeModal={this.closeSettings} visible={this.state.settings_open}>
                    <IntroSettingsModal close={this.closeSettings} />
                </Modal>
                { this.state.errors.length > 0 &&
                    <div className={styles.floatingAlerts}>
                        <Alert severity="error">This is an error alert - error</Alert>
                    </div>
                }
            </div>
        );
    }
}

class RequestActions extends Component {
    constructor() {
        super();

        this.state = { delete: 0 };
    }
    
    // Helper Functions
    deleteIntro = async () => {
        if (this.state.delete === 0) {
            this.setState({ delete: 1 });
        } else if (this.state.delete === 1) {
            let { prospect } = this.props.data;
            let result = await editIntroRequestWith(prospect, null, -1);
            await this.props.reloadData();
        }
    }
    
    makeIntro = () => {
        this.props.makeIntro(this.props.data);
    }
    
    // Render Event
    render() {
        return (
            <span>
                <ButtonSmall color="green" onClick={this.makeIntro} style={{ boxShadow: 'none', marginRight: 10, padding: '7px 23px' }} title="Make Intro" />
                <ButtonSmall
                    color={this.state.delete === 0 ? "gray" : "red"}
                    onClick={this.deleteIntro}
                    style={{ boxShadow: 'none', lineHeight: '20px', padding: '7px 23px' }}
                    title={this.state.delete === 0 ? "Delete" : "Really Delete?"}
                />
            </span>
        );
    }
}

class ScoreIndicator extends Component {
    render() {
        let { conflict, isPear, score } = this.props;
        score = conflict ? 0 : score;
        if (isPear) {
            return (
                <div className={styles.pearIndicator} />
            );
        }
        return (
            <div className={styles.scoreIndicator} style={{ backgroundColor: colorFor(score).main }} />
        );
    }
}

class IntroSettingsModal extends Component {
    constructor() {
        super();
        this.state = {
            blurb: "",
            email: ""
        };

        this.contentEditable = React.createRef();
        this.mergeTags = [
            "Company Name",
            "Investor First Name",
            "Fundraising Round",
            "Company Blurb"
        ];
    }

    // Lifecycle Events
    componentDidMount() {
        this.loadData();
    }

    // Helper Functions
    editBlurb = (e) => {
        let blurb = e.target.value;
        this.setState({ blurb: blurb });
    }
    
    insertMergeTag = (e) => {
        let tag = e.target.value;
        let { blurb } = this.state;
        let position = this.contentEditable.current.selectionStart;
        let start = blurb.substring(0, position);
        let end = blurb.substring(position, blurb.length);
        let newBlurb = start + "{{" + tag + "}}" + end;
        this.setState({
            blurb: newBlurb
        });
        this.contentEditable.current.focus();
    }

    loadData = async () => {
        let settings = await introSettings();
        this.setState({
            blurb: settings.intro_blurb || "",
            email: settings.email
        });
    }
    
    renderBlurb = () => {
        let { blurb } = this.state;
        return blurb;
    }
    
    saveSettings = async () => {
        await saveIntroBlurbAs(this.state.blurb);
        this.props.close();
    }
    
    // Render Event
    render() {
        return (
            <div className={styles.modal}>
                <h1>Intro Settings</h1>
                <div className={styles.inputs}>
                    <div>
                        <h2>Your Sender Email</h2>
                        <input className={styles.email} readOnly type="text" value={this.state.email} />
                    </div>
                    <div>
                        <div className={styles.controls}>
                            <h2>Your Intro Blurb</h2>
                            <FormControl className={styles.insertTag}>
                                <InputLabel id="label">Insert Merge Tag</InputLabel>
                                <Select
                                    labelId="label"
                                    onChange={this.insertMergeTag}
                                    value={""}
                                >
                                    {
                                        this.mergeTags.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                            {/* <button onClick={this.insertMergeTag}>Insert Merge Tag</button> */}
                        </div>
                        <textarea className={styles.editor} ref={this.contentEditable} onChange={this.editBlurb} value={this.state.blurb} />
                        {/* <div className={styles.editor}>
                            <Editor editorState={this.renderBlurb()} ref={this.contentEditable} onChange={this.editBlurb} />
                        </div> */}
                    </div>
                </div>
                <div className={styles.buttons}>
                    <ButtonLarge color="green" onClick={this.saveSettings} title="Save" />
                </div>
            </div>
        );
    }
}

class CreateRequestGroup extends Component {
    constructor() {
        super();

        this.state = { creating: false };
    }

    // Helper Functions
    endCreating = () => { this.setState({ creating: false }); }
    setCreating = () => { this.setState({ creating: true }); }

    // Render Event
    render() {
        let { creating } = this.state;
        return (
            <span className={styles.createGroup}>
                { creating ? (
                    <CreateRequestPopup
                        close={this.endCreating}
                        makeIntro={this.props.makeIntro}
                        reload={this.props.reload}
                    />
                ) : (
                    <ButtonSmall color="yellow" style={{ boxShadow: 'none' }} title="Add Intro Request" onClick={this.setCreating} />
                )}
            </span>
        );
    }
}

class CreateRequestPopup extends Component {
    constructor() {
        super();

        this.state = {
            companies: [],
            company: "",
            filteredInvestors: [],
            investor: null,
            loadingAdd: false,
            loadingAddAndIntro: false
        };
    }

    // Lifecycle Events
    componentDidMount() {
        this.loadCompanies();
    }

    // Helper Functions
    createRequest = async (load=true) => {
        // This should all be on the backend. Cobbling together on the frontend for now for speedier execution.
        // Bad style? Yes. Does it work? Pretty much.
        let { company, investor, statuses } = this.state;
        this.setState({ company: "", investor: null, loadingAdd: load });
        let fundingRound = company.id;
        let investorId = investor.id;
        let prospect = await findProspectForFirmWith(investorId, fundingRound);
        let statusIntroInProgress = (statuses.filter(x => x.name === "Intro in Progress"))[0];
        let canIntroStatuses = ["New", "Intro Requested", "Intro in Progress"];
        let statusForIntro = statuses.filter(x => canIntroStatuses.includes(x.name)).map(x => x.id);
        let selectedProspect = null;
        if (prospect.temp) {
            selectedProspect = (await saveProspectForFirmWith(prospect.result.firm.id, fundingRound, prospect.result.tags, statusIntroInProgress.id)).prospect;
        } else if (statusForIntro.includes(prospect.result.firm.status)) {
            selectedProspect = prospect.result.firm.prospect;
        }
        if (selectedProspect) {
            let request = await introRequestFor(selectedProspect);
            this.setState({ loadingAdd: false });
            this.props.reload();
            return {
                funding_round: fundingRound,
                investor_id: investorId,
                investor: investor.name,
                requester: request.requester,
                prospect: selectedProspect,
                company: company.org
            };
        }
        this.setState({ loadingAdd: false });
        return null;
    }

    createRequestAndIntro = async () => {
        this.setState({ loadingAddAndIntro: true });
        let request = await this.createRequest(false);
        console.log(request);
        if (request) {
            this.props.makeIntro(request);
        }
        this.setState({ loadingAddAndIntro: false });
    }

    loadCompanies = async () => {
        let rounds = await fundingRounds();
        let statuses = await prospectStates();
        this.setState({ companies: rounds, statuses: statuses });
    }

    // Render Event
    render() {
        let { companies, company, filteredInvestors, investor, loadingAdd, loadingAddAndIntro } = this.state;
        return (
            <div className={styles.createPopup}>
                <FormControl className={styles.createInput}>
                    <InputLabel id="create-for-company">Company</InputLabel>
                    <Select
                        value={company}
                        onChange={(event, target) => {
                            this.setState({ company: target.props.value });
                            console.log(target.props.value);
                        }}
                        labelId="create-for-company"
                        displayEmpty
                    >
                        {
                            companies.map(x => <MenuItem key={x.id} value={x}>{x.org}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Autocomplete
                    value={investor}
                    getOptionLabel={(option) => (option.name)}
                    onChange={(event, newValue) => {
                        this.setState({ investor: newValue });
                    }}
                    onInputChange={async (event, newInputValue) => {
                        let newOptions = await searchFor(newInputValue);
                        this.setState({ filteredInvestors: newOptions });
                    }}
                    filterOptions={x => x}
                    options={filteredInvestors}
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Investor" />
                    )}
                    className={styles.createInput}
                />
                <ButtonSmall color="green" loading={loadingAdd} title="Add" onClick={this.createRequest} style={{ boxShadow: 'none', height: "40px", margin: "10px 10px 0" }} />
                <ButtonSmall color="yellow" loading={loadingAddAndIntro} title="Add & Make Intro" onClick={this.createRequestAndIntro} style={{ boxShadow: 'none', height: "40px", margin: "10px 10px 0 0" }} />
                <ButtonSmall color="gray" title="Cancel" onClick={this.props.close} style={{ boxShadow: 'none', height: "40px", marginTop: "10px" }} />
            </div>
        );
    }
}