import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fundingRounds } from '../../api/admin';
import { ButtonLarge, ButtonSmall, LoadingView, Modal, NavBar } from '../base';
import { colorFor, dateDisplayFrom, daysSince } from '../formatting';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styles from './styles/Portfolio.module.scss';

export class Portfolio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: -1,
            funding_rounds: [],
            loading: true,
            target_capital: 0,
            raised_capital: 0,
            target_valuation: 0
        };

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        document.title = "Orchard - Fundraising Rounds";
        this.loadData();
    }

    async loadData() {
        let data = await fundingRounds();
        this.setState({
            funding_rounds: data,
            loading: false
        });
    }
    
    render() {
        let { funding_rounds } = this.state;
        return (
            <div className={styles.portfolioLayout}>
                <NavBar
                    title="Fundraising Rounds"
                />
                { !this.state.loading ? (
                    <div className={styles.items}>
                        {
                            funding_rounds.map(x => <PortfolioItem data={x} key={x.company} />)
                        }
                    </div>
                ) : (
                    <LoadingView />
                )}
            </div>
        );
    }
}

class PortfolioItem extends Component {
    render() {
        let age = (new Date() - new Date(this.props.data.date_created))/1000/60/60/24/7;
        let score = age <= 1 ? 1 : this.props.data.raised_capital/this.props.data.target_capital || 0;
        let color = colorFor(score);
        return (
            <Link to={"/company/" + this.props.data.company}>
                <div
                    className={styles.portfolioItem}
                >
                    <div className={styles.portfolioItemHeader} style={{background: color.main}} />
                    <div className={styles.portfolioItemContent}>
                        <div className={styles.left}>
                            <img className={styles.logo} src={"//logo.clearbit.com/" + this.props.data.org_domain} />
                            <div>
                                <h1>{this.props.data.org}</h1>
                                <h2>{this.props.data.round_type}</h2>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.prospectInfo}>
                                <h3>{this.props.data.intro_requests} intro requests</h3>
                                <h3>{this.props.data.term_sheets} term sheets</h3>
                            </div>
                            <div className={styles.portfolioItemScore}>
                                <CircularProgressbar
                                    value={ score }
                                    maxValue={1}
                                    strokeWidth={12}
                                    styles={{
                                        path: {
                                            stroke: color.light
                                        },
                                        trail: {
                                            stroke: score > 0 ? '#E8E8E8' : color.light
                                        }
                                    }}
                                />
                                <span>{ (score * 10).toFixed(1) }</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.portfolioItemFooter}>
                        <span>raising since { 
                            this.props.data.date_created && (dateDisplayFrom(this.props.data.date_created) + " " + daysSince(this.props.data.date_created))
                        }</span>
                    </div>
                </div>
            </Link>
        );
    }
}