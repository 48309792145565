import React, { Component } from 'react';

import { signUp } from '../api';

import styles from './styles/Auth.module.css';
import googleLogo from './images/google.svg';

export class LoginButton extends Component {
    render() {
        return (
            <div className={styles.googleButton} onClick={signUp}>
                <div className={styles.googleLogoArea}>
                    <img className={styles.googleLogo} src={googleLogo} />
                </div>
                <p>Sign in with Google</p>
            </div>
        );
    };
}