import React, { Component } from 'react';

import styles from './styles/Search.module.scss';
import arrow from './images/arrow.svg';
import { findProspectForFirmWith, saveFirmWith, saveProspectForFirmWith, searchFor } from '../api';
import { ProspectModal } from './prospect';
import { LoadingView } from './base';
import { FirmModal } from './firm';

export class SearchModal extends Component {
    constructor() {
        super();

        this.state = {
            searching: 1,
            suggestions: [],
            term: ""
        };
        this.closeCreate = this.closeCreate.bind(this);
        this.createFirm = this.createFirm.bind(this);
        this.search = this.search.bind(this);
        this.select = this.select.bind(this);
        this.updateConflict = this.updateConflict.bind(this);
        this.updateLead = this.updateLead.bind(this);
        this.updateScore = this.updateScore.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.updateTag = this.updateTag.bind(this);
        this.save = this.save.bind(this);
        this.saveInvestorWith = this.saveInvestorWith.bind(this);
    }

    componentDidMount() {
        try {
            this.searchInput.focus();
        } catch(e) {}
    }
    
    closeCreate() {
        this.setState({ searching: 1 });
    }
    
    createFirm() {
        this.setState({ searching: 2 });
    }

    async search(e) {
        let value = e.target.value;
        this.setState({ term: value });
        if (value.length < 1) {
            this.setState({ suggestions: [] });
        } else {
            let suggestions = await searchFor(value);
            this.setState({ suggestions: suggestions });
        }
    }

    async select(id) {
        this.setState({ searching: -1 });
        let prospect = await findProspectForFirmWith(id, this.props.fundingRound);
        console.log(prospect);
        if (prospect["temp"] === false) {
            this.props.openExisting(prospect["result"]);
        } else {
            this.setState({
                newProspect: prospect["result"],
                searching: 0
            });
        }
    }

    updateConflict(prospect) {
        let { newProspect } = this.state;
        newProspect["has_conflict"] = false;
        this.setState({ newProspect: newProspect });
    }
    
    updateLead(prospect, value) {
        let { newProspect } = this.state;
        newProspect.firm["lead"] = value;
        this.setState({ newProspect: newProspect });
    }
    
    updateScore() {
        let score = (this.state.newProspect.tags.reduce(function(accumulator, item) {
            return accumulator += item["value"] ? 1 : 0;
        }, 0.0))/this.state.newProspect.tags.length;
        let { newProspect } = this.state;
        newProspect["score"] = score;
        this.setState({ newProspect: newProspect });
    }

    updateStatus(prospect, value) {
        let { newProspect } = this.state;
        newProspect.firm["status"] = value;
        this.setState({ newProspect: newProspect });
    }

    updateTag(id, prospect, value) {
        let { newProspect } = this.state;
        for (var i = 0; i !== newProspect.tags.length; i++) {
            let tag = newProspect.tags[i];
            if (tag.tag === id) {
                tag["value"] = value;
                tag["autocompleted"] = false;
            }
        }
        this.setState({ newProspect: newProspect });
        this.updateScore();
    }

    async save() {
        await saveProspectForFirmWith(
            this.state.newProspect.firm.id,
            this.props.fundingRound,
            this.state.newProspect.tags,
            this.state.newProspect.firm.status
        );
        this.props.close();
    }

    async saveInvestorWith(data) {
        let newFirm = await saveFirmWith(data);
        if (newFirm) {
            await this.select(newFirm.id);
        }
    }
    
    render() {
        return this.state.searching === 1 ? (
            <div className={styles.container}>
                <input
                    className={styles.search}
                    placeholder="Start typing an investor name..."
                    onChange={this.search}
                    required="required"
                    type="text"
                    value={this.state.term}
                    ref={(input) => { this.searchInput = input; }}
                />
                <img className={styles.arrow} src={arrow} />
                <Suggestions
                    data={this.state.suggestions}
                    term={this.state.term}
                    select={this.select}
                    create={this.createFirm}
                />
            </div>
        ) : this.state.searching === 0 ? (
            <ProspectModal
                data={this.state.newProspect}
                new={true}
                states={this.props.states}
                updateConflict={this.updateConflict}
                updateLead={this.updateLead}
                updateScore={this.updateScore}
                updateStatus={this.updateStatus}
                updateTag={this.updateTag}
                save={this.save}
            />
        ) : this.state.searching === 2 ? (
            <FirmModal
                cancel={ this.closeCreate }
                name={ this.state.term }
                save={ this.saveInvestorWith }
            />
        ) : (
            <LoadingView color="white" />
        );
    }
}

class Suggestions extends Component {
    render() {
        return (
            <div className={styles.suggestionsContainer}>
                { this.props.data && this.props.data.length > 0 &&
                    this.props.data.map(x =>
                        <Suggestion
                            domain={x.domain}
                            id={x.id}
                            key={x.id}
                            name={x.name}
                            select={this.props.select}
                        />    
                    )
                }
                { this.props.data.length === 0 &&
                    <Suggestion
                        name={"Add new investor \"" + this.props.term + "\""}
                        select={this.props.create}
                    />
                }
            </div>
        );
    }
}

class Suggestion extends Component {
    constructor() {
        super()

        this.select = this.select.bind(this);
    }

    select() {
        this.props.select(this.props.id);
    }

    render() {
        return (
            <div className={styles.suggestion} onClick={this.select}>
                <span>{ this.props.name }</span>
            </div>
        );
    }
}