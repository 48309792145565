/* Imports */
// React
import React, { Component, PureComponent } from 'react';

// Material UI
import { TextField } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

// Other UI
import ReactNumeric from 'react-numeric';

// APIs
import { saveNewNoteWith, updateProspectWith } from '../api';

// UIs
import modalStyles from './styles/Firm.module.scss';
import styles from './styles/Rating.module.scss';
import { ButtonLarge } from './base';

/* Class Declarations */
export class RatingModal extends Component {
    constructor() {
        super();
        this.state = { page: 0 }
    }
    
    render() {
        let { page } = this.state;
        return (
            <div className={modalStyles.rating}>
                <h1 className={modalStyles.title}>Please provide some feedback on <span className={modalStyles.term}>{this.props.firm["name"]}</span></h1>
                { page === 0 &&
                    <RatingModalRate
                        close={this.props.close}
                        firm={this.props.firm}
                        passStatus={this.props.passStatus}
                        prospect={this.props.firm["prospect"]}
                        ratingType={this.props.ratingType}
                    />
                }
            </div>
        );
    }
}

class RatingModalRate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            committedAmount: null,
            committedValuation: null,
            passNote: "",
            rating: null,
            submitting: false,
            timeToMeeting: null
        }
    }
    
    componentDidMount() {
        const { firm } = this.props;
        let estimatedTime = Math.ceil((new Date() - new Date(firm.date_created))/1000/60/60/24);
        let estimatedDecisionTime = firm.time_to_meeting ? estimatedTime - firm.time_to_meeting : null;
        this.setState({
            timeToDecision: estimatedDecisionTime !== null && estimatedDecisionTime >= 0 ? estimatedDecisionTime : null,
            timeToMeeting: estimatedTime
        });
    }
    
    inputValid = () => {
        let { committedAmount, committedValuation, passNote, rating, timeToDecision, timeToMeeting } = this.state;
        let { ratingType } = this.props;
        if (ratingType === "passed") return rating !== null && passNote !== "" && timeToDecision;
        if (ratingType === "term_sheet") return rating !== null && committedAmount && committedValuation && timeToDecision;
        if (ratingType === "first_meeting") return timeToMeeting;
    }

    submit = async () => {
        this.setState({ submitting: true });
        let { committedAmount, committedValuation, passNote, rating, timeToDecision, timeToMeeting } = this.state;
        let { passStatus, prospect, ratingType } = this.props;
        if (ratingType === "term_sheet") {
            await Promise.all([
                updateProspectWith(prospect, "amount", committedAmount),
                updateProspectWith(prospect, "valuation", committedValuation),
                updateProspectWith(prospect, "rating", rating),
                updateProspectWith(prospect, "time_to_decision", timeToDecision)
            ]);
        } else if (ratingType === "passed") {
            await Promise.all([
                saveNewNoteWith(passNote, prospect, passStatus),
                updateProspectWith(prospect, "rating", rating),
                updateProspectWith(prospect, "time_to_decision", timeToDecision)
            ]);
        } else if (ratingType === "first_meeting") {
            await updateProspectWith(prospect, "time_to_meeting", timeToMeeting);
        }
        this.props.close();
    }

    render() {
        let { committedAmount, committedValuation, passNote, rating, submitting, timeToDecision, timeToMeeting } = this.state;
        return (
            <div className={styles.ratingModal}>
                { ["passed", "term_sheet"].includes(this.props.ratingType) &&
                    <div className={styles.inputItem}>
                        <h1>How do you rate this investor?</h1>
                        <Rating
                            value={rating}
                            onChange={(event, value) => { this.setState({ rating: value }); }}
                        />
                    </div>
                }
                { this.props.ratingType === "term_sheet" &&
                    <div className={styles.inputItem}>
                        <h1>What are the termsheet terms?</h1>
                        <ReactNumeric
                            className={styles.termSheetItem}
                            currencySymbol="$"
                            decimalPlaces={0}
                            onChange={(event, value) => { this.setState({ committedAmount: value }); }}
                            placeholder="Committed amount"
                            value={committedAmount}
                        />
                        <ReactNumeric
                            className={styles.termSheetItem}
                            currencySymbol="$"
                            decimalPlaces={0}
                            onChange={(event, value) => { this.setState({ committedValuation: value }); }}
                            placeholder="Valuation"
                            value={committedValuation}
                        />
                    </div>
                }
                { this.props.ratingType === "passed" &&
                    <div className={styles.inputItem}>
                        <h1>What reasoning did the investor provide for passing?</h1>
                        <TextField
                            name="PassReasoning"
                            onChange={(event) => { this.setState({ passNote: event.target.value }); }}
                            placeholder="Reasoning"
                            value={ passNote }
                        />
                    </div>
                }
                { ["passed", "term_sheet"].includes(this.props.ratingType) &&
                    <div className={styles.inputItem}>
                        <h1>Starting from your first meeting, about how long did it take for this investor to get to a decision?</h1>
                        <span>
                            <TextField
                                name="DecisionTime"
                                onChange={(event) => { this.setState({ timeToDecision: event.target.value }); }}
                                placeholder="Time in Days"
                                type="number"
                                value={ timeToDecision }
                            />
                            <h2>days</h2>
                        </span>
                    </div>
                }
                { this.props.ratingType === "first_meeting" &&
                    <div className={styles.inputItem}>
                        <h1>How long did it take to get to the first meeting?</h1>
                        <span>
                            <TextField
                                name="FirstMeeting"
                                onChange={(event) => { this.setState({ timeToMeeting: event.target.value }); }}
                                placeholder="Time in Days"
                                type="number"
                                value={ timeToMeeting }
                            />
                            <h2>days</h2>
                        </span>
                    </div>
                }
                <ButtonLarge color="green" disabled={!this.inputValid()} loading={submitting} onClick={this.submit} style={{ boxShadow: 'none', marginTop: '20px', width: '100%' }} title="Submit" />
            </div>
        );
    }
}