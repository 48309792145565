import React, { Component } from 'react';
import { MenuItem, Select, TextField, withStyles } from '@material-ui/core';
import { companyWith, fundingRounds } from '../../api/admin';
import { ButtonLarge, ButtonSmall, LoadingView, Modal, NavBar } from '../base';

import styles from './styles/Company.module.scss';
import { PipelineLayout } from '../pipeline';
import { Info } from './info';

export class CompanyLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: {},
            view: 'pipeline'
        };
        this.loadData = this.loadData.bind(this);
        this.setView = this.setView.bind(this);
    }
    
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let company = await companyWith(this.props.org);
        document.title = "Orchard - " + company.name;
        this.setState({
            company: company
        });
    }

    setView(e) {
        let value = e.target.value;
        this.setState({ view: value });
    }
    
    render() {
        let { view } = this.state;
        return (
            <div className={styles.companyLayout}>
                <NavBar title={this.state.company["name"]}>
                    <Select
                        value={this.state.view}
                        onChange={this.setView}
                        className={styles.view}
                        variant="outlined"
                    >
                        <MenuItem key="pipeline" value="pipeline">Financing Pipeline</MenuItem>
                        <MenuItem key="pear-info" value="pear-info">Pear Info</MenuItem>
                    </Select>
                </NavBar>
                { view === 'pipeline' ? (
                        <PipelineLayout admin={this.props.admin} org={this.props.org} />
                    ) : 
                    view === 'pear-info' ? (
                        <Info admin={this.props.admin} info={this.state.company} org={this.props.org} />
                    ) : (
                        <div />
                    )
                }
            </div>
        );
    }
}