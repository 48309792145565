import { authGETRequestWith, authPOSTRequestWith, authPUTRequestWith } from './config';

export async function fundingRoundWith(id) {
    let data = await authGETRequestWith("funding-round", {id: id});
    return data;
}

export async function currentFundingRound(org) {
    let data = await authGETRequestWith("funding-round-current", {
        org: org
    });
    return data;
}

export async function previousFundingRound(org) {
    let data = await authGETRequestWith("funding-round-previous", {
        org: org
    });
    return data;
}

export async function fundingRoundTypes() {
    let result = await authGETRequestWith(["tags"], {
        name: "funding_stage"
    });
    return result;
}

export async function createFundingRound(data) {
    let result = await authPUTRequestWith(["funding-round"], data);
    return result;
}

export async function closeFundingRound(id) {
    let result = await authPOSTRequestWith(["funding-round-close"], {id: id});
    return result;
}