export function colorFor(score) {
    const colors = {
        green: {
            main: '#04B900',
            light: '#93DF96'
        },
        yellow: {
            main: '#FFD234',
            light: '#F1D677'
        },
        red: {
            main: '#FF3434',
            light: '#FF9E9E'
        }
    };
    return score <= 0.25 ? colors.red : score <= 0.6 ? colors.yellow : colors.green;
}

export function dateDisplayFrom(date) {
    date = new Date(date);
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    return [month, day, year].join("/");
}

export function daysSince(date) {
    let today = new Date();
    date = new Date(date);
    let diff = parseInt((today - date)/1000/60/60/24);
    if (diff > 30) {
        return "(" + parseInt(diff/30).toString() + " months)";
    }
    return "(" + diff.toString() + " days)";
}

export function moneyDisplayFrom(amount) {
    if (!amount) return "";
    let value = "$";
    if (amount >= 1000000000) {
        return value + (amount/1000000000).toString() + "B";
    }
    if (amount >= 1000000) {
        return value + (amount/1000000).toString() + "M";
    }
    if (amount >= 1000) {
        return value + (amount/1000).toString() + "k";
    }
    return value + amount.toString();
}