import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import CircularProgress from '@material-ui/core/CircularProgress';

import layout from './styles/BaseLayout.module.scss';
import ui from './styles/BaseUI.module.scss';

import logo from './images/logo.svg';
import toggle from './images/toggle.svg';

export class BaseLayout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={layout.base}>
                <NavDrawer pages={this.props.pages} setPage={this.props.setPage} />
                { this.props.children }
            </div>
        );
    }
}

export class ButtonLarge extends Component {
    onClick = () => {
        if (!this.props.disabled && !this.props.loading) this.props.onClick();
    }

    render() {
        let className = ui.buttonLarge;
        switch (this.props.color) {
            case "gray":
                className = `${ui.buttonLarge} ${ui.backgroundGray}`;
                break;
            case "green":
                className = `${ui.buttonLarge} ${ui.backgroundGreen}`;
                break;
            case "red":
                className = `${ui.buttonLarge} ${ui.backgroundRed}`;
                break;
            case "yellow":
                className = `${ui.buttonLarge} ${ui.backgroundYellow}`;
                break;
            default:
                className = `${ui.buttonLarge} ${ui.backgroundBlack}`;
        }
        if (this.props.loading) className = `${ui.buttonLarge} ${ui.backgroundGray}`;
        if (this.props.disabled) className = `${ui.buttonLarge} ${ui.disabled}`;
        return (
            <button className={className} onClick={this.onClick} style={this.props.style}>
                { !this.props.loading && this.props.title }
                { this.props.loading &&
                    <ClipLoader color="#FFFFFF" size={15} />
                }
            </button>
        );
    }
}

export class ButtonSmall extends Component {
    onClick = () => {
        if (!this.props.disabled && !this.props.loading) this.props.onClick();
    }

    render() {
        let className = ui.buttonSmall;
        switch (this.props.color) {
            case "gray":
                className = `${ui.buttonSmall} ${ui.backgroundGray}`;
                break;
            case "green":
                className = `${ui.buttonSmall} ${ui.backgroundGreen}`;
                break;
            case "red":
                className = `${ui.buttonSmall} ${ui.backgroundRed}`;
                break;
            case "yellow":
                className = `${ui.buttonSmall} ${ui.backgroundYellow}`;
                break;
            default:
                className = `${ui.buttonSmall} ${ui.backgroundBlack}`;
        }
        if (this.props.disabled) className = `${ui.buttonSmall} ${ui.disabled}`;
        return (
            <button className={className} onClick={this.onClick} style={this.props.style}>
                { !this.props.loading ? this.props.title : <CircularProgress color="#FFFFFF" size={15} style={{ marginTop: "4px" }} thickness={5} /> }
            </button>
        );
    }
}

export class HomeLayout extends Component {
    render() {
        return (
            <div className={layout.home}>
                { this.props.children }
            </div>
        );
    }
}

export class LoadingView extends Component {
    render() {
        return (
            <div className={layout.loading}>
                <PropagateLoader color={this.props.color || "#AAAAAA"} />
            </div>
        );
    }
}

export class LoginSection extends Component {
    render() {
        return (
            <div className={ui.loginSection}>
                { this.props.children }
            </div>
        );
    }
}

export class Logo extends Component {
    render() {
        return (
            <img src={logo} />
        );
    }
}

export class Modal extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className={ui.modalContainer} style={{
                display: this.props.visible ? 'flex' : 'none'
            }}>
                { this.props.visible && this.props.children }
                <div className={ui.modalBackground} onClick={this.props.closeModal} />
            </div>
        );
    }
}

class NavDrawer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            open: false // Inherit from layout snapshot
        }
    }

    render() {
        return (
            <div className={layout.navDrawerContainer}>
                
                <input className={layout.navDrawerControl} defaultChecked={true} id="toggle" type="checkbox" />
                <label className={layout.navDrawerToggle} htmlFor="toggle">
                    <img src={toggle} />
                </label>
                <div className={layout.navDrawer}>
                    {
                        this.props.pages.map(x =>
                            <NavDrawerItem
                                title={x.name}
                                page={x.page}
                                setPage={this.props.setPage}
                                icon={x.icon}
                                key={x.page}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}

class NavDrawerItem extends Component {
    constructor(props) {
        super(props);

        this.setPage = this.setPage.bind(this);
    }

    setPage() {
        this.props.setPage(this.props.page);
    }

    render() {
        return (
            <Link to={this.props.page}>
                <div className={layout.navDrawerItem}>
                    <div className={layout.navDrawerItemIcon}>
                        <img src={this.props.icon} />
                    </div>
                    <h1>{ this.props.title }</h1>
                </div>
            </Link>
        );
    }
}

export class NavBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={layout.navBar}>
                <h1>{ this.props.title }</h1>
                <div className={layout.navBarButtons}>
                    { this.props.children }
                </div>
            </div>
        );
    }
}