import React, { useState, useEffect } from 'react';

import { LoginButton } from '../components/auth';
import { LoadingView } from '../components/base';
import { AdminView, FounderView, LoginView } from './view';
import { useAuth } from '../config/auth';
import styles from '../styles/Home.module.css'

export default function Home() {
    // const loginStatus = isLoggedIn();
    const { loading, token, userData } = useAuth();
    console.log(token);
    let user = JSON.parse(userData);

    if (loading) {
        return (
            <div className={styles.container}>
                <LoadingView />
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                { token ? (
                    user.admin ? (
                        <AdminView />
                    ) : (
                        <FounderView
                            org={user.org}
                        />
                    )
                ) : (
                    <LoginView />
                )}
            </div>
        );
    }
}
