import React, { Component } from 'react';

import { closeFundingRound, currentFundingRound, previousFundingRound } from '../api';
import { ButtonLarge, LoadingView, NavBar } from './base';
import { moneyDisplayFrom } from './formatting';
import { Kanban } from './kanban';
import { Onboarding } from './onboarding';

import styles from './styles/Pipeline.module.scss';

export class PipelineLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: -1,
            target_capital: 0,
            raised_capital: 0,
            target_valuation: 0,
            close_error: 0
        };

        this.closeRound = this.closeRound.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let [current, previous] = await Promise.all([currentFundingRound(this.props.org), previousFundingRound(this.props.org)]);
        if (current) {
            this.setState({
                target_capital: current.target_capital,
                target_valuation: current.target_valuation,
                raised_capital: current.raised_capital,
                funding_round: current.id,
                former_raised: previous.raised_capital || null,
                former_valuation: previous.target_valuation || null,
                active: 1
            });
        } else {
            if (!this.props.admin) {
                this.setState({
                    active: 0
                });
            } else {
                window.location.replace("/404");
            }
        }
    }

    async closeRound() {
        if (this.state.close_error === 0) {
            this.setState({close_error: 1});
        } else {
            this.setState({close_error: 2});
            let success = await closeFundingRound(this.state.funding_round);
            if (success["success"]) {
                // Show congrats
                this.setState({close_error: 3});
                window.location.replace("/404");
            } else {
                this.setState({close_error: -1});
            }
        }
    }

    render() {
        return (
            <div className={styles.pipelineLayout}>
                { !this.props.admin &&
                    <NavBar title="Investor Pipeline">
                        <ButtonLarge
                            color={this.state.close_error === 0 ? "yellow" : this.state.close_error === 1 ? "green" : "red"}
                            loading={this.state.close_error === 2}
                            onClick={this.closeRound}
                            title={this.state.close_error === 0 ? "Close Round" : this.state.close_error === 1 ? "Really Close Round?" : "Uh, oh. Your round isn’t full."}
                        />
                    </NavBar>
                }
                { this.state.active === 1 ? (
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <FinancingBar
                            target_capital={this.state.target_capital}
                            target_valuation={this.state.target_valuation}
                            raised_capital={this.state.raised_capital}
                            previous_capital={this.state.former_raised}
                            previous_valuation={this.state.former_valuation}
                        />
                        <Kanban fundingRound={this.state.funding_round} org={this.props.org} />
                    </div>
                ) : this.state.active === 0 ? (
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                        <Onboarding
                            finish={this.loadData}
                        />
                    </div>
                ) : (
                    <LoadingView />
                )}
            </div>
        );
    }
}

class FinancingBar extends Component {
    render() {
        return (
            <div className={styles.financingBar}>
                <div className={styles.financingBarData}>
                    <div className={styles.financingBarItems}>
                        <FinancingBarItem
                            margin={true}
                            title="Target Raise"
                            value={this.props.target_capital}
                        />
                        <FinancingBarItem
                            margin={true}
                            title="Target Valuation"
                            value={this.props.target_valuation}
                        />
                        <FinancingBarItem
                            title="Total Commitments"
                            value={this.props.raised_capital}
                        />
                    </div>
                    { this.props.previous_capital &&
                        <div className={styles.financingBarItems}>
                            <div className={styles.financingBarSeparator} />
                            <FinancingBarItem
                                margin={true}
                                title="Former Valuation"
                                value={this.props.previous_valuation}
                            />
                            <FinancingBarItem
                                title="Former Raised"
                                value={this.props.previous_capital}
                            />
                        </div>
                    }
                </div>
                <div>

                </div>
            </div>
        );
    }
}

class FinancingBarItem extends Component {
    render() {
        return (
            <div
                className={styles.financingBarItem}
                style={{
                    marginRight: this.props.margin ? 50 : 0
                }}
            >
                <h1>{ this.props.title }</h1>
                <h2>{ moneyDisplayFrom(this.props.value) }</h2>
            </div>
        );
    }
}