import { authGETRequestWith, authPOSTRequestWith } from './config';

export async function editIntroRequestWith(prospect, partner, status) {
    return await authPOSTRequestWith(["intro"], {
        partner: partner,
        prospect: prospect,
        status: status
    });
}

export async function fundingRounds() {
    return await authGETRequestWith(["admin", "funding-rounds"]);
}

export async function introRequests(status) {
    return await authGETRequestWith(["admin", "intro-requests"], {status: status});
}

export async function introRequestBlurb(funding_round, investor, org) {
    return await authGETRequestWith(["admin", "intro-request-blurb"], {
        funding_round: funding_round,
        investor: investor,
        org: org
    });
}

export async function introSettings() {
    return await authGETRequestWith(["admin", "intro-settings"]);
}

export async function saveIntroBlurbAs(blurb) {
    return await authPOSTRequestWith(["admin", "intro-blurb"], {blurb, blurb});
}

export async function companyWith(id) {
    return await authGETRequestWith(["admin", "company"], {id: id});
}

export async function saveCompanyDataWith(id, info) {
    return await authPOSTRequestWith(["admin", "company"], {org: id, ...info});
}