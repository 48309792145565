import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ReactNumeric from 'react-numeric';

import { partnersFor, saveTagWith, updateProspectWith } from '../api/prospect';
import { ButtonLarge, ButtonSmall } from './base';
import { colorFor, dateDisplayFrom } from './formatting';
import { Notes } from './notes';
import { altGet, itemWithKeyFrom } from './utils';

import styles from './styles/Prospect.module.scss';
import { PartnerModal } from './partner';
import { introRequestFor } from '../api/intros';

const STATUS_NEW = "new"; // TODO: refactor this to something more scalable
const STATUS_INTRO_REQUEST = 'intro_requested';
const STATUS_TERM_SHEET = "term_sheet";

export class ProspectModal extends Component {
    constructor() {
        super();

        this.state = {
            creating: 0,
            notesOpen: false,
            newIntroRequested: false
        };
        
        this.openCreatePartner = this.openCreatePartner.bind(this);
        this.cancelCreatePartner = this.cancelCreatePartner.bind(this);
        this.nonConflict = this.nonConflict.bind(this);
        this.openNotes = this.openNotes.bind(this);
        this.requestIntro = this.requestIntro.bind(this);
    }

    async nonConflict() {
        if (this.props.data.firm["prospect"]) await updateProspectWith(this.props.data.firm.prospect, "has_conflict", false);
        this.props.updateConflict(this.props.data.firm.id, false);
    }
    
    cancelCreatePartner() {
        this.setState({ creating: 0 });
    }
    
    createPartner() {

    }
    
    mainStageFrom(stages) {
        if (!stages || stages === {} || Object.keys(stages).length == 0) {
            return "";
        } else {
            return (Object.keys(stages).reduce(function(a, k) {
                if (stages[k] > a.value) {
                    a = {
                        name: k,
                        value: stages[k]
                    };
                }
                return a;
            }, {value: 0, name: ""})).name;
        }
    }
    
    openCreatePartner() {
        this.setState({ creating: 1 });
    }
    
    openNotes() {
        this.setState({
            notesOpen: !this.state.notesOpen
        });
    }

    async requestIntro() {
        this.setState({ newIntroRequested: true });
        await introRequestFor(this.props.data.firm.prospect);
        let statesMapping = this.props.states.reduce((a, v) => {
            a[v.name] = v.id;
            return a;
        }, {});

        await updateProspectWith(this.props.data.firm.prospect, "status", statesMapping["Intro Requested"]);
        await this.props.updateStatus(this.props.data.firm.prospect, statesMapping["Intro Requested"]);
        await this.props.setIntroRequested(this.props.data.firm.prospect);
    }
    
    tagsFrom(tags) {
        return tags.reduce(function(accumulator, item) {
            accumulator[item.name] = item;
            return accumulator;
        }, {});
    }

    render() {
        let score = this.props.data.has_conflict ? 0 : this.props.data.score;
        let color = colorFor(score);
        let tags = this.tagsFrom(this.props.data.tags);
        let offerings = [
            {
                tag_name: "Useful Connections",
                name: "Useful connections"
            },
            {
                tag_name: "Domain Expertise",
                name: "Relevant domain expertise"
            },
            {
                tag_name: "Useful Tools & Services",
                name: "Useful tools and services"
            },
            {
                tag_name: "Stage Fit",
                name: "Stage fit (current stage)"
            },
            {
                tag_name: "Reputable Brand",
                name: "Reputable brand as a firm"
            }
        ];
        let stage_tier = this.mainStageFrom(this.props.data.firm.stages) + (altGet(this.props.data.firm.tier, "name", null) ? " / " + this.props.data.firm.tier.name : "");

        return this.props.data && this.state.creating === 0 ? (
            <div className={styles.prospect} style={{
                maxWidth: this.state.notesOpen ? '900px' : '716px'
            }}>
                <div className={styles.main}>
                    <ProspectModalTopIndicator color={color.main} />
                    <div className={styles.container}>
                        <div className={styles.prospectContent}>
                            <div className={styles.prospectSummaryArea}>
                                <div className={styles.prospectSummary}>
                                    <h1>{ this.props.data.firm.name }</h1>
                                    <a href={ "http://" + this.props.data.firm.domain } target="_blank">{ this.props.data.firm.domain }</a>
                                    <h2>{ stage_tier }</h2>
                                    { (!this.props.data.intro_request && ((this.props.data.firm.stage_type === STATUS_NEW && !this.props.new && !this.props.data.has_conflict) ||
                                            (this.props.data.firm.stage_type === STATUS_INTRO_REQUEST))
                                        ) &&
                                        <ButtonLarge disabled={this.state.newIntroRequested} onClick={this.requestIntro} title={this.state.newIntroRequested ? "Intro Requested" : "Request Intro"} />
                                    }
                                    { this.props.data.has_conflict &&
                                        <ButtonLarge onClick={this.nonConflict} title="Mark as Non-Conflict" />
                                    }
                                    { this.props.new && !this.props.data.has_conflict &&
                                        <ButtonLarge title="Save Prospect" color="yellow" onClick={this.props.save} />
                                    }
                                </div>
                                <div className={styles.prospectSummaryLeft}>
                                    <div className={styles.prospectScore}>
                                        <CircularProgressbar
                                            value={ score }
                                            maxValue={1}
                                            strokeWidth={12}
                                            styles={{
                                                path: {
                                                    stroke: color.light
                                                },
                                                trail: {
                                                    stroke: score > 0 ? '#E8E8E8' : color.light
                                                }
                                            }}
                                        />
                                        <span>{ this.props.data.has_conflict ? "!" : (this.props.data.score * 10).toFixed(1) }</span>
                                    </div>
                                    { !this.props.new &&
                                        <ButtonSmall color="yellow" onClick={this.openNotes} title={!this.state.notesOpen ? "Notes" : "Close Notes"} />
                                    }
                                </div>
                            </div>
                            <div className={styles.prospectDivider} />
                            <div className={styles.prospectInfoContainer}>
                                { this.props.data.firm.stage_type === STATUS_TERM_SHEET &&
                                    <ProspectModalInfoItem name={"Term Sheet"}>
                                        <TermSheetBasics
                                            amount={this.props.data.firm.amount || ""}
                                            firm={this.props.data.firm.id}
                                            prospect={this.props.data.firm["prospect"]}
                                            valuation={this.props.data.firm.valuation || ""}
                                            // round={this.props.data.firm.amount}
                                            update={this.props.updateTermSheet}
                                        />
                                    </ProspectModalInfoItem>
                                }
                                { this.props.data.firm.stage_type !== STATUS_TERM_SHEET && <ProspectModalInfoItemText
                                    items={this.props.states}
                                    name={"Status"}
                                    value={altGet(itemWithKeyFrom(this.props.states, "id", this.props.data.firm.status), "name", null)}
                                    prospect={altGet(this.props.data.firm, "prospect", null)}
                                    attribute="status"
                                    update={this.props.updateStatus}
                                /> }
                                { this.props.data.firm.stage_type !== STATUS_NEW && <ProspectModalInfoItemText
                                    createNew={this.openCreatePartner}
                                    name={"Partner"}
                                    value={[this.props.data.firm.partner_first_name, this.props.data.firm.partner_last_name].join(" ")}
                                    fetchData={partnersFor}
                                    dataKey={altGet(this.props.data.firm, "id", null)}
                                    prospect={altGet(this.props.data.firm, "prospect", null)}
                                    attribute="partner"
                                    canAdd={true}
                                />}
                                <ProspectModalInfoItemText
                                    items={[{name: "Lead", id: true}, {name: "Follow", id: false}]}
                                    name={"Investment Type"}
                                    value={this.props.data.firm.is_lead ? "Lead" : "Follow"}
                                    prospect={altGet(this.props.data.firm, "prospect", null)}
                                    attribute="is_lead"
                                    noSearch
                                    update={this.props.updateLead}
                                />
                                <ProspectModalInfoItem name={"Offerings"}>
                                    {
                                        offerings.map(x =>
                                            <ProspectModalCheckbox
                                                autoprediction={altGet(tags[x.tag_name], "autocompleted", false)}
                                                value={altGet(tags[x.tag_name], "value", false)}
                                                name={x.name}
                                                id={altGet(tags[x.tag_name], "tag", false)}
                                                key={altGet(tags[x.tag_name], "tag", false)}
                                                prospect={altGet(this.props.data.firm, "prospect", null)}
                                                firm={this.props.data.firm.id}
                                                update={this.props.updateTag}
                                            />
                                        )
                                    }
                                </ProspectModalInfoItem>
                            </div>
                        </div>
                        { !this.props.new && this.state.notesOpen &&
                            <Notes
                                prospect={this.props.data.firm.prospect}
                                status={itemWithKeyFrom(this.props.states, "id", this.props.data.firm.status)}
                            />
                        }
                    </div>
                    <ProspectModalFooter
                        added={new Date(altGet(this.props.data.firm, "date_created", null))}
                        conflict={this.props.data.has_conflict}
                    />
                </div>
            </div>
        ) : this.state.creating === 1 ? (
            <PartnerModal
                cancel={ this.cancelCreatePartner }
                firm={this.props.data.firm.id}
                name={ this.state.term }
            />
        ) : (
            <div />
        );
    }
}

class ProspectModalCheckbox extends Component {
    constructor() {
        super();

        this.state = {
            value: false
        };

        this.setTag = this.setTag.bind(this);
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    async setTag(e) {
        const value = e.target.checked;
        this.setState({
            value: value
        });
        if (this.props.prospect) await saveTagWith(this.props.id, this.props.prospect, value);
        if (this.props.update) {
            this.props.update(this.props.id, this.props.prospect, value, this.props["firm"]);
        }
    }
    
    render() {
        return (
            <div className={styles.prospectCheckboxContainer} style={this.props.autoprediction ? {
                borderColor: '#3C5AF4',
                height: '44px'
            } : {}}>
                <label className={styles.prospectCheckbox}>{ this.props.name }
                    <input checked={this.state.value} onChange={this.setTag} type="checkbox" />
                    <span className={styles.prospectCheckmark} />
                </label>
                { this.props.autoprediction &&
                    <span className={styles.prospectCheckboxAutoprediction}>Auto-prediction. Is this accurate?</span>
                }
            </div>
        );
    }
}

class ProspectModalFooter extends Component {
    render() {
        return (
            <div className={this.props.conflict ? styles.prospectFooterRed : styles.prospectFooter}>
                { this.props.conflict ?
                    <h1>POTENTIAL PORTFOLIO CONFLICT</h1>
                    :
                    <h1>ADDED ON {dateDisplayFrom(this.props.added)}</h1>
                }
            </div>
        );
    }
}

class ProspectModalInfoItem extends Component {
    constructor() {
        super();
    }
    
    render() {
        return (
            <div className={styles.prospectInfoItem}>
                <div className={styles.prospectInfoItemDisplayStatic} onClick={this.startEdit}>
                    <h1>{ this.props.name }</h1>
                    { this.props.children }
                </div>
            </div>
        );
    }
}

class ProspectModalInfoItemText extends Component {
    constructor() {
        super();

        this.state = {
            editing: false,
            items: [],
            search: ""
        };

        this.createNew = this.createNew.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.editSearch = this.editSearch.bind(this);
        this.escPress = this.escPress.bind(this);
        this.loadItems = this.loadItems.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.select = this.select.bind(this);
        this.startEdit = this.startEdit.bind(this);
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
        document.addEventListener("keydown", this.escPress, false);
        this.loadItems();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escPress, false);
    }

    cancelEdit() {
        this.setState({ editing: false });
    }

    createNew() {
        this.props.createNew();
    }
    
    editSearch(e) {
        this.setState({ search: e.target.value });
    }

    escPress(e) {
        if (e.keyCode === 27) {
            this.cancelEdit();
        }
    }
    
    async select(id, name) {
        this.setState({
            editing: false,
            value: name
        });
        if (this.props.update) {
            this.props.update(this.props.prospect, id);
        }
        await updateProspectWith(this.props.prospect, this.props.attribute, id);
    }

    startEdit() {
        this.setState({ editing: true });
    }

    async loadItems() {
        if (this.props.items) {
            this.setState({ items: this.props.items });
        } else {
            let data = await this.props.fetchData(this.props.dataKey);
            this.setState({ items: data.map(x => ({
                id: x.id,
                name: [x.first_name, x.last_name].join(" ")
            })) });
        }
    }

    renderItem(x) {
        if (x.name.toLowerCase().includes(this.state.search.toLowerCase()) || this.props.api) {
            return <InfoItemAutofill id={x.id} key={x.id} name={x.name} onClick={this.select} />
        };
    }
    
    render() {
        return (
            <div className={styles.prospectInfoItem}>
                { !this.state.editing ? (
                    <div className={styles.prospectInfoItemDisplay} onClick={this.startEdit}>
                        <h1>{ this.props.name }</h1>
                        <h2>{ this.state.value }</h2>
                    </div>
                ) : (
                    <div className={styles.prospectInfoItemEditorDisplay}>
                        <h1>{ this.props.name }</h1>
                        <div className={styles.prospectInfoItemEditor}>
                            <h2>Select { this.props.name }</h2>
                            { !this.props.noSearch &&
                                <input onChange={this.editSearch} type="text" value={this.state.search}></input>
                            }
                            { this.props.canAdd &&
                                <InfoItemAutofill id={-1} name={"Create new"} onClick={this.createNew} optional={true} />
                            }
                            <div className={styles.prospectInfoItemEditorPicker}>
                                {
                                    this.state.items.map(x => this.renderItem(x))
                                }
                            </div>
                        </div>
                        <div className={styles.prospectInfoItemEditorPickerCancel} onClick={this.cancelEdit} />
                    </div>
                )}
            </div>
        );
    }
}

class InfoItemAutofill extends Component {
    constructor() {
        super();

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.onClick(this.props.id, this.props.name);
    }

    render() {
        return (
            <div
                className={styles.prospectInfoItemAutofill}
                onClick={this.onClick}
                style={this.props.optional ? {
                    borderBottom: "1px solid #EBEBEB",
                    marginBottom: "3px",
                    paddingBottom: "7px"
                } : {}}
            >
                <span style={{color: this.props.optional ? "#AAAAAA" : "#000000"}}>{ this.props.name }</span>
            </div>
        );
    }
}

class ProspectModalTopIndicator extends Component {
    render() {
        return (
            <div className={styles.prospectTopIndicator} style={{background: this.props.color}} />
        );
    }
}

class TermSheetBasics extends Component  {
    constructor() {
        super();

        this.state = {
            amount: "",
            originalAmount: "",
            originalValuation: "",
            valuation: ""
        };
        
        this.cancelAmount = this.cancelAmount.bind(this);
        this.cancelValuation = this.cancelValuation.bind(this);
        this.saveAmount = this.saveAmount.bind(this);
        this.saveValuation = this.saveValuation.bind(this);
        this.updateAmount = this.updateAmount.bind(this);
        this.updateValuation = this.updateValuation.bind(this);
    }

    componentDidMount() {
        this.setState({
            amount: this.props.amount,
            originalAmount: this.props.amount,
            originalValuation: this.props.valuation,
            valuation: this.props.valuation
        });
    }

    async cancelAmount() {
        this.setState({
            amount: this.state.originalAmount
        });
    }

    async cancelValuation() {
        this.setState({
            valuation: this.state.originalValuation
        });
    }
    
    async updateAmount(e, value) {
        this.setState({
            amount: value
        });
    }

    async updateValuation(e, value) {
        this.setState({
            valuation: value
        });
    }

    async saveAmount() {
        this.setState({
            originalAmount: this.state.amount
        });
        await updateProspectWith(this.props.prospect, "amount", parseFloat(this.state.amount));
        this.props.update(this.props.firm, "amount", parseFloat(this.state.amount));
    }

    async saveValuation() {
        this.setState({
            originalValuation: this.state.valuation
        });
        await updateProspectWith(this.props.prospect, "valuation", this.state.valuation);
        this.props.update(this.props.firm, "valuation", this.state.valuation);
    }
    
    render() {
        return (
            <table className={styles.termSheetBasicsTable}>
                <tr>
                    <td className={styles.label}>Amount:</td>
                    <td className={styles.value}>
                        <ReactNumeric
                            className={styles.termSheetBasicsInput}
                            currencySymbol="$"
                            decimalPlaces={0}
                            onChange={this.updateAmount}
                            placeholder="Enter the committed amount"
                            value={this.state.amount}
                        />
                    </td>
                    { this.state.amount != this.state.originalAmount && <td>
                        <ButtonSmall color="gray" onClick={this.cancelAmount} title="Cancel" />
                    </td>}
                    { this.state.amount != this.state.originalAmount && <td>
                        <ButtonSmall color="green" onClick={this.saveAmount} title="Save" />
                    </td>}
                </tr>
                <tr>
                    <td className={styles.label}>Valuation:</td>
                    <td className={styles.value}>
                        <ReactNumeric
                            className={styles.termSheetBasicsInput}
                            currencySymbol="$"
                            decimalPlaces={0}
                            onChange={this.updateValuation}
                            placeholder="Enter post-money valuation"
                            value={this.state.valuation}
                        />
                    </td>
                    { this.state.valuation != this.state.originalValuation && <td>
                        <ButtonSmall color="gray" onClick={this.cancelValuation} title="Cancel" />
                    </td>}
                    { this.state.valuation != this.state.originalValuation && <td>
                        <ButtonSmall color="green" onClick={this.saveValuation} title="Save" />
                    </td>}
                </tr>
                {/* <tr>
                    <td className={styles.label}>Round Size:</td>
                    <td className={styles.value}>
                        <ReactNumeric
                            className={styles.termSheetBasicsInput}
                            currencySymbol="$"
                            decimalPlaces={0}
                            placeholder="Unchanged"
                            value={this.props.round}
                        />
                    </td>
                </tr> */}
            </table>
        );
    }
}