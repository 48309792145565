import React, { Component } from 'react';
import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';

import { createConnectionWith, deleteConnectionWith, getPartnersFrom, saveFirmWith } from '../../api';

import styles from './styles/Requests.module.scss';
import { ButtonLarge, ButtonSmall, LoadingView, Modal, NavBar } from '../base';
import { PartnerModal } from '../partner';
import { FirmModal } from '../firm';

export class Contacts extends Component {
    constructor() {
        super();
        this.state = {
            contacts: [],
            modal: null,
            page: 0,
            pageLoading: false,
            selectionModel: [],
            total: 0,
            errors: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ page: 0, pageLoading: true });
        let data = (await getPartnersFrom(0, 2000)) || [];
        this.setState({
            contacts: data.data,
            loading: false,
            pageLoading: false,
            total: data.rows
        });
    }

    saveFirm = async (data) => {
        let success = await saveFirmWith(data);
        if (!success) {
            let { errors } = this.state;
            errors.push({
                message: "Error: " + data.name + " already exists in the database",
                severity: "error"
            });
            this.setState({
                errors: errors
            });
        } else {
            let { errors } = this.state;
            errors.push({
                message: "Success: " + data.name + " added to database",
                severity: "success"
            });
            let index = errors.length - 1;
            this.setState({
                errors: errors
            });
            setTimeout(() => {
                errors.splice(index, 1);
                this.setState({
                    errors: errors
                });
            }, 3000);
        }
        this.setState({ modal: null });
    }

    setPageTo = async (page) => {
        this.setState({ page: page.page });
    }

    updateConnection = (status, id) => {
        let { contacts } = this.state;
        let index = contacts.map(x => x.id).indexOf(id);
        contacts[index]["connected"] = status;
        this.setState({ contacts: contacts });
    }

    render() {
        let { contacts, page, pageLoading, selectionModel, total } = this.state;
        const fields = [
            { field: "first_name", headerName: "First Name", flex: 1 },
            { field: "last_name", headerName: "Last Name", flex: 1 },
            { field: "email", headerName: "Email", flex: 2 },
            { field: "org", headerName: "Firm", flex: 2 },
            { field: "role", headerName: "Role", flex: 1 },
            {
                field: "actions",
                headerName: "Actions",
                disableClickEventBubbling: true,
                renderCell: (params) => (
                    <ContactActions data={params.row} id={params.id} makeIntro={this.setIntroingTo} update={this.updateConnection} />
                ),
                flex: 3
            }
        ];
        return (
            <div className={styles.layout}>
                <NavBar title="Contacts">
                    <ButtonLarge color="yellow" onClick={() => this.setState({ modal: "person" }) } style={{ marginRight: "15px" }} title="Add Person" />
                    <ButtonLarge color="yellow" onClick={() => this.setState({ modal: "firm" }) } title="Add Firm" />
                </NavBar>
                <div className={styles.container}>
                    { !this.state.loading ? (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Toolbar className={styles.toolbar}>
                                {selectionModel.length > 0 ? (
                                    <Typography className={styles.title} color="inherit" variant="subtitle1" component="div">
                                        {selectionModel.length} selected
                                    </Typography>
                                ) : (
                                    <Typography className={styles.title} color="inherit" variant="subtitle1" component="div">
                                        All Contacts
                                    </Typography>
                                )}
                                {selectionModel.length > 0 && (
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="delete" onClick={this.deleteSelected}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Toolbar>
                            <DataGrid
                                rows={contacts}
                                columns={fields}
                                onSelectionModelChange={(newSelection) => {
                                    this.setSelectionModel(newSelection.selectionModel);
                                }}
                                selectionModel={selectionModel}
                                checkboxSelection
                                disableSelectionOnClick
                                // rowCount={total}
                                // paginationMode="server"
                                onPageChange={(newPage) => this.setPageTo(newPage)}
                                page={page}
                                loading={pageLoading}
                            />
                        </div>
                    ) : (
                        <LoadingView />
                    )}
                </div>
                <Modal closeModal={() => this.setState({ modal: null })} visible={this.state.modal === "person"}>
                    <PartnerModal cancel={() => this.setState({ modal: null })} />
                </Modal>
                <Modal closeModal={() => this.setState({ modal: null })} visible={this.state.modal === "firm"}>
                    <FirmModal cancel={() => this.setState({ modal: null })} save={this.saveFirm} />
                </Modal>
                { this.state.errors.length > 0 &&
                    <div className={styles.floatingAlerts}>
                        {
                            this.state.errors.map((x, index) => <Alert onClose={() => {
                                let { errors } = this.state;
                                errors.splice(index, 1);
                                console.log(errors);
                                this.setState({ errors: errors });
                            }} severity={x.severity}>{x.message}</Alert>)
                        }
                    </div>
                }
            </div>
        );
    }
}

class ContactActions extends Component {
    deleteConnection = async () => {
        let user = this.props.data.id;
        this.props.update(false, this.props.id);
        await deleteConnectionWith(user);
    }

    createMildConnection = async () => {
        let user = this.props.data.id;
        let strength = 0.5;
        this.props.update(true, this.props.id);
        await createConnectionWith(user, strength);
    }

    createStrongConnection = async () => {
        let user = this.props.data.id;
        let strength = 1;
        this.props.update(true, this.props.id);
        await createConnectionWith(user, strength);
    }
    
    render() {
        return (
            <span>
                { this.props.data.connected ? (
                    <span>
                        <ButtonSmall
                            color="gray"
                            onClick={this.deleteConnection}
                            style={{ marginRight: 10, padding: '7px 23px', boxShadow: 'none' }}
                            title="I don't know them"
                        />
                    </span>
                ) : (
                    <span>
                        <ButtonSmall
                            color="yellow"
                            onClick={this.createMildConnection}
                            style={{ marginRight: 10, padding: '7px 23px', boxShadow: 'none' }}
                            title="I know them"
                        />
                        <ButtonSmall
                            color="green"
                            onClick={this.createStrongConnection}
                            style={{ marginRight: 10, padding: '7px 23px', boxShadow: 'none' }}
                            title="I know them well"
                        />
                    </span>
                )}
            </span>
        );
    }
}