import { authGETRequestWith } from './config';

export async function autocompleteCompanyWith(term) {
    let result = await authGETRequestWith(["data", "company"], {
        "term": term
    });
    return result;
}

export async function autocompleteSectorWith(term) {
    let result = await authGETRequestWith(["data", "sector"], {
        "term": term
    });
    return result;
}