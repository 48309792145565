import React, { Component } from 'react';

import { AuthProvider } from './config/auth';
import { auth, providers } from './config/firebase';
import Home from './pages/home';

import './styles/globals.css';

auth.onIdTokenChanged(function(user) {
    if (user) {
        console.log(user);
    }
});

class App extends Component {
    render() {
        return (
            <AuthProvider>
                <Home />
            </AuthProvider>
        );
    }
}

export default App
