import { isLoggedInAsync } from '../api';
import { auth, providers } from './firebase';
import React, { createContext, Context, useContext, useState, useEffect } from 'react';

const authContext = React.createContext({
    loading: true,
    token: null
});

function useProvideAuth() {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);

    const changeAuth = async function(user) {
        if (!user) {
            setToken(null);
            setLoading(false);
            setUserData(null);
            return;
        }
        try {
            let token = await user.getIdToken();
            let loggedIn = await isLoggedInAsync();
            if (loggedIn) {
                setUserData(JSON.stringify(loggedIn));
                setToken(token);
            } else {
                setToken(null);
            }
            setLoading(false);
        } catch(e) {
            setToken(null);
            setLoading(false);
            setUserData(null);
        }
    }
    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(changeAuth);
    });

    return {
        token,
        loading,
        userData
    }
}

export function AuthProvider({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);