import React, { Component } from 'react';
import { MenuItem, Select, TextField, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import { autocompleteCompanyWith, autocompleteSectorWith, createFundingRound, fundingRoundTypes, previousFundingRound } from '../api';
import { ButtonLarge, LoadingView } from './base';

import styles from './styles/Onboarding.module.scss';

const OnboardingInput = withStyles({
    textField: {
        '& label.Mui-focused': {
          color: '#AAAAAA',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#AFAFAF',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#AAAAAA',
          },
        },
      }
})(CurrencyTextField);

const OnboardingAutocomplete = withStyles({
    root: {
        '& label.Mui-focused': {
          color: '#AAAAAA',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#AFAFAF',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#AAAAAA',
          },
        },
      }
})(Autocomplete);

export class Onboarding extends Component {
    // For now, we don't save state
    // Consider saving state to localStorage
    constructor() {
        super();
        
        this.state = {
            data: {
                raised_capital: 0,
                round_type: "",
                target_capital: "",
                target_valuation: "",
                competitors: [],
                sectors: [],
                previous_capital: "",
                previous_valuation: ""
            },
            previousRound: null,
            screen: 0
        };

        this.finish = this.finish.bind(this);
        this.loadData = this.loadData.bind(this);
        this.nextFrom = this.nextFrom.bind(this);
        this.previousFrom = this.previousFrom.bind(this);
        this.setCompetitors = this.setCompetitors.bind(this);
        this.setPreviousCapital = this.setPreviousCapital.bind(this);
        this.setPreviousValuation = this.setPreviousValuation.bind(this);
        this.setRoundType = this.setRoundType.bind(this);
        this.setSectors = this.setSectors.bind(this);
        this.setTargetCapital = this.setTargetCapital.bind(this);
        this.setTargetValuation = this.setTargetValuation.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async finish() {
        await createFundingRound(this.state.data);
        this.props.finish();
    }
    
    async loadData() {
        let previousRound = await previousFundingRound();
        if (previousRound) {
            this.setState({
                previousRound: previousRound
            });
        }
    }
    
    nextFrom(index) {
        this.setState({ screen: index + 1 });
    }
    
    previousFrom(index) {
        this.setState({ screen: index - 1 });
    }

    setCompetitors(value) {
        let { data } = this.state;
        data["competitors"] = value;
        this.setState({ data: data });
    }

    setRoundType(value) {
        let { data } = this.state;
        data["round_type"] = value;
        this.setState({ data: data });
    }

    setSectors(value) {
        let { data } = this.state;
        data["sectors"] = value;
        this.setState({ data: data });
    }
    
    setTargetCapital(value) {
        let { data } = this.state;
        data["target_capital"] = value;
        this.setState({ data: data });
    }

    setTargetValuation(value) {
        let { data } = this.state;
        data["target_valuation"] = value;
        this.setState({ data: data });
    }

    setPreviousCapital(value) {
        let { data } = this.state;
        data["previous_capital"] = value;
        this.setState({ data: data });
    }

    setPreviousValuation(value) {
        let { data } = this.state;
        data["previous_valuation"] = value;
        this.setState({ data: data });
    }

    render() {
        let { screen } = this.state;
        return (
            screen === 0 ? (
                <Start
                    index={0}
                    next={this.nextFrom}
                />
            ) : screen === 1 ? (
                <RoundType
                    index={1}
                    next={this.nextFrom}
                    onChange={this.setRoundType}
                    previous={this.previousFrom}
                    value={this.state.data.round_type}
                />
            ) : screen === 2 ? (
                <Goals
                    index={2}
                    next={this.nextFrom}
                    previous={this.previousFrom}
                    setTargetCapital={this.setTargetCapital}
                    setTargetValuation={this.setTargetValuation}
                    targetCapital={this.state.data.target_capital}
                    targetValuation={this.state.data.target_valuation}
                />
            ) : screen === 3 ? (
                <Competitors
                    finish={this.finish}
                    index={3}
                    next={this.nextFrom}
                    onChange={this.setCompetitors}
                    previous={this.previousFrom}
                    value={this.state.data.competitors}
                    previousRound={this.state.previousRound != null}
                />
            ) : screen === 4 ? (
                <Sectors
                    finish={this.finish}
                    index={4}
                    next={this.nextFrom}
                    onChange={this.setSectors}
                    previous={this.previousFrom}
                    value={this.state.data.sectors}
                    previousRound={this.state.previousRound != null}
                />
            ) : screen === 5 ? (
                <History
                    index={5}
                    finish={this.finish}
                    previous={this.previousFrom}
                    setPreviousCapital={this.setPreviousCapital}
                    setPreviousValuation={this.setPreviousValuation}
                    previousCapital={this.state.data.previous_capital}
                    previousValuation={this.state.data.previous_valuation}
                />
            ) : (
                <LoadingView />
            )
        )
    }
}

class Start extends Component {
    constructor() {
        super();
        this.next = this.next.bind(this);
    }
    
    next() {
        this.props.next(this.props.index);
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.centered}>
                    <h1 className={styles.message}>No Active Fundraising Round</h1>
                    <ButtonLarge color="yellow" onClick={this.next} title="Create Round" />
                </div>
            </div>
        );
    }
}

class RoundType extends Component {
    constructor() {
        super();

        this.state = {
            options: []
        };

        this.loadData = this.loadData.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.select = this.select.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let stages = await fundingRoundTypes();
        this.setState({
            options: stages
        });
        if (this.props.value === "") this.props.onChange(stages[0]["id"]);
    }

    next() {
        if (this.props.value) this.props.next(this.props.index);
    }

    previous() {
        this.props.previous(this.props.index)
    }

    select(e) {
        let value = e.target.value;
        this.props.onChange(value);
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.centered}>
                    <h1 className={styles.message}>What type of round is this?</h1>
                    <Select
                        value={this.props.value}
                        onChange={this.select}
                        className={styles.selector}
                        variant="outlined"
                    >
                        {
                            this.state.options.map(x => (
                                <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                            ))
                        }
                    </Select>
                </div>
                <div className={styles.footer}>
                    <ButtonLarge color="gray" onClick={this.previous} style={{width: "250px"}} title="Back" />
                    <ButtonLarge color="green" onClick={this.next} style={{width: "250px"}} title="Next" />
                </div>
            </div>
        );
    }
}

class Goals extends Component {
    constructor() {
        super();

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.setTargetCapital = this.setTargetCapital.bind(this);
        this.setTargetValuation = this.setTargetValuation.bind(this);
    }

    componentDidMount() {
        console.log(this.props.targetCapital);
    }

    next() {
        if (this.props.targetCapital != "" && this.props.targetValuation != "") this.props.next(this.props.index);
    }

    previous() {
        this.props.previous(this.props.index);
    }

    setTargetCapital(e) {
        let value = e.target.value;
        this.props.setTargetCapital(value.replaceAll(",", ""));
    }

    setTargetValuation(e) {
        let value = e.target.value;
        this.props.setTargetValuation(value.replaceAll(",", ""));
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.centered}>
                    <h1 className={styles.message}>Let’s jot down your goals for this round</h1>
                    <div className={styles.grid}>
                        <OnboardingInput
                            className={styles.text}
                            disableUnderline
                            label="Target Capital"
                            onChange={this.setTargetCapital}
                            currencySymbol="$"
                            decimalPlaces={0}
                            variant="outlined"
                            value={this.props.targetCapital.replaceAll(",", "")}
                        />
                        <OnboardingInput
                            className={styles.text}
                            disableUnderline
                            label="Target Valuation"
                            onChange={this.setTargetValuation}
                            currencySymbol="$"
                            decimalPlaces={0}
                            variant="outlined"
                            value={this.props.targetValuation.replaceAll(",", "")}
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    <ButtonLarge color="gray" onClick={this.previous} style={{width: "250px"}} title="Back" />
                    <ButtonLarge
                        color={this.props.targetCapital != "" && this.props.targetValuation != "" ? "green" : "gray"}
                        onClick={this.next}
                        style={{width: "250px"}}
                        title="Next"
                    />
                </div>
            </div>
        );
    }
}

class Competitors extends Component {
    constructor() {
        super();

        this.state = {
            options: [],
            term: ""
        };

        this.searchTimer = null;

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.searchFor = this.searchFor.bind(this);
        this.select = this.select.bind(this);
    }
    
    next() {
        if (!this.props.previousRound) {
            this.props.next(this.props.index);
        } else {
            this.props.finish();
        }
    }
    
    previous() {
        this.props.previous(this.props.index);
    }

    async searchFor(e) {
        if (this.searchTimer) clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(async () => {
            let term = e.target.value;
            let companies = await autocompleteCompanyWith(term);
            this.setState({
                options: companies
            });
        }, this.props.delayTime || 500);
    }

    select(e, value) {
        this.props.onChange(value);
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.centered}>
                    <h1 className={styles.message}>Enter your competitors</h1>
                    <h2 className={styles.submessage}>We’ll tell you if an investor has a conflict</h2>
                    <OnboardingAutocomplete
                        className={styles.competitors}
                        multiple
                        id="tags-standard"
                        options={this.state.options}
                        getOptionLabel={(option) => option.name}
                        value={this.props.value}
                        onChange={this.select}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={this.state.term}
                                onChange={this.searchFor}
                                variant="outlined"
                                label="Competitors"
                                placeholder="Company"
                            />
                        )}
                        renderOption={(option) => 
                            <div className={styles.competitorOption}>
                                <img className={styles.logo} src={"https://res-3.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco/" + option.image} />
                                <img className={styles.hover} src={"https://res-3.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco/" + option.image} />
                                <span>{option.name}</span>
                            </div>
                        }
                    />
                </div>
                <div className={styles.footer}>
                    <ButtonLarge color="gray" onClick={this.previous} style={{width: "250px"}} title="Back" />
                    <ButtonLarge color="green" onClick={this.next} style={{width: "250px"}} title="Next" />
                </div>
            </div>
        );
    }
}

class Sectors extends Component {
    constructor() {
        super();

        this.state = {
            options: [],
            term: ""
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.searchFor = this.searchFor.bind(this);
        this.select = this.select.bind(this);
    }
    
    next() {
        if (!this.props.previousRound) {
            this.props.next(this.props.index);
        } else {
            this.props.finish();
        }
    }
    
    previous() {
        this.props.previous(this.props.index);
    }

    async searchFor(e) {
        let term = e.target.value;
        let sectors = await autocompleteSectorWith(term);
        this.setState({
            options: sectors
        });
    }

    select(e, value) {
        console.log(value);
        this.props.onChange(value);
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.centered}>
                    <h1 className={styles.message}>Enter your top 3 sectors</h1>
                    <h2 className={styles.submessage}>This helps us figure out if an investor has relevant expertise</h2>
                    <OnboardingAutocomplete
                        className={styles.competitors}
                        multiple
                        id="sectors-standard"
                        options={this.state.options}
                        getOptionLabel={(option) => option.name}
                        value={this.props.value}
                        onChange={this.select}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={this.state.term}
                                onChange={this.searchFor}
                                variant="outlined"
                                label="Sectors"
                                placeholder="Sector"
                            />
                        )}
                        renderOption={(option) => 
                            <div className={styles.competitorOption}>
                                <span>{option.name}</span>
                            </div>
                        }
                    />
                </div>
                <div className={styles.footer}>
                    <ButtonLarge color="gray" onClick={this.previous} style={{width: "250px"}} title="Back" />
                    <ButtonLarge color="green" onClick={this.next} style={{width: "250px"}} title="Next" />
                </div>
            </div>
        );
    }
}

class History extends Component {
    constructor() {
        super();

        this.finish = this.finish.bind(this);
        this.previous = this.previous.bind(this);
        this.setPreviousCapital = this.setPreviousCapital.bind(this);
        this.setPreviousValuation = this.setPreviousValuation.bind(this);
    }

    finish() {
        if (this.props.previousCapital != "" && this.props.previousValuation != "") this.props.finish();
    }
    
    previous() {
        this.props.previous(this.props.index);
    }
    
    setPreviousCapital(e) {
        let value = e.target.value;
        this.props.setPreviousCapital(value.replaceAll(",", ""));
    }

    setPreviousValuation(e) {
        let value = e.target.value;
        this.props.setPreviousValuation(value.replaceAll(",", ""));
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.centered}>
                    <h1 className={styles.message}>This is your first pipeline in Orchard</h1>
                    <h2 className={styles.submessage}>Enter your fundraising history</h2>
                    <div className={styles.grid}>
                        <OnboardingInput
                            className={styles.text}
                            currencySymbol="$"
                            decimalPlaces={0}
                            label="Capital Raised to Date"
                            variant="outlined"
                            onChange={this.setPreviousCapital}
                            value={this.props.previousCapital}
                        />
                        <OnboardingInput
                            className={styles.text}
                            currencySymbol="$"
                            decimalPlaces={0}
                            label="Current Valuation"
                            variant="outlined"
                            onChange={this.setPreviousValuation}
                            value={this.props.previousValuation}
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    <ButtonLarge color="gray" onClick={this.previous} style={{width: "250px"}} title="Back" />
                    <ButtonLarge
                        color={this.props.previousCapital != "" && this.props.previousValuation != "" ? "green" : "gray"}
                        onClick={this.finish}
                        style={{width: "250px"}}
                        title="Next"
                    />
                </div>
            </div>
        );
    }
}