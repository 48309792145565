import { authRequestWith } from './config';

export async function deleteConnectionWith(user) {
    return await authRequestWith(["connection"], "DELETE", {
        user: user
    });
}

export async function createConnectionWith(user, strength) {
    return await authRequestWith(["connection"], "POST", {
        user: user,
        strength: strength
    });
}