import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
} from "react-router-dom";

import { Contacts } from '../components/admin/contacts';
import { Portfolio } from '../components/admin/portfolio';
import { CompanyLayout } from '../components/admin/company';
import { LoginButton } from '../components/auth';
import { BaseLayout, HomeLayout, LoginSection, Logo } from '../components/base';
import { PipelineLayout } from '../components/pipeline';
import { Requests } from '../components/admin/requests';

import financing from './images/menu/financing.svg';
import history from './images/menu/history.svg';
import intro from './images/menu/intro.svg';
import phone from './images/menu/phone.svg';

function Company(admin=false) {
    let { companyId } = useParams();
    return (
        <CompanyLayout admin={admin} org={companyId} />
    );
}

export class AdminView extends Component {
    constructor() {
        super();
        
        this.pages = [
            {
                name: "Rounds",
                page: "/rounds",
                icon: financing
            },
            {
                name: "Requests",
                page: "/requests",
                icon: intro
            },
            {
                name: "Contacts",
                page: "/contacts",
                icon: phone
            },
            // {
            //     name: "History",
            //     page: "/history",
            //     icon: history
            // }
        ];

        this.state = {
            page: 'rounds'
        };

        this.setPageTo = this.setPageTo.bind(this);
    }

    setPageTo(page) {
        this.setState({ page: page });
    }
    
    render() {
        return (
            <Router>
                <BaseLayout pages={this.pages} setPage={this.setPageTo}>
                    <Switch>
                        <Route path="/company/:companyId">
                            <Company admin={true} />
                        </Route>
                        <Route path="/rounds">
                            <Portfolio org={this.props.org} />
                        </Route>
                        <Route path="/requests">
                            <Requests />
                        </Route>
                        <Route path="/contacts">
                            <Contacts />
                        </Route>
                        <Route path="/">
                            <Portfolio org={this.props.org} />
                        </Route>
                    </Switch>
                </BaseLayout>
            </Router>
        );
    }
}

export class FounderView extends Component {
    constructor() {
        super();
        
        this.pages = [
            {
                name: "Financing",
                page: "pipeline",
                icon: financing
            }
            // {
            //     name: "History",
            //     page: "history",
            //     icon: history
            // }
        ];

        this.state = {
            page: 'pipeline'
        };

        this.setPageTo = this.setPageTo.bind(this);
    }
    
    setPageTo(page) {
        this.setState({ page: page });
    }

    render() {
        return (
            <Router>
                <BaseLayout pages={this.pages} setPage={this.setPageTo}>
                    <Switch>
                        <Route path="/pipeline">
                            <PipelineLayout org={this.props.org} />
                        </Route>
                        <Route path="/">
                            <PipelineLayout org={this.props.org} />
                        </Route>
                    </Switch>
                </BaseLayout>
            </Router>
        );
    }
}

export class LoginView extends Component {
    render() {
        return (
            <HomeLayout>
                <Logo />
                <LoginSection>
                    <h1>Fundraising made simple</h1>
                    <h2>Build and manage your financing pipeline, hassle-free</h2>
                    <LoginButton />
                </LoginSection>
            </HomeLayout>
        );
    }
}