import { authGETRequestWith, authPUTRequestWith } from './config';

export async function notesFor(prospect) {
    let data = await authGETRequestWith("note", {prospect: prospect});
    return data;
}

export async function saveNewNoteWith(content, prospect, status) {
    let data = await authPUTRequestWith("note", {content: content, prospect: prospect, status: status});
    return data;
}