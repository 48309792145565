import { authGETRequestWith, authPOSTRequestWith } from './config';

export async function searchFor(firm) {
    let result = await authGETRequestWith(["search", "firms"], {
        "term": firm
    });
    return result;
}

export async function firmStages() {
    let result = await authGETRequestWith(["tags"], {
        name: "firm_stage"
    });
    return result;
}

export async function firmTiers() {
    let result = await authGETRequestWith(["tags"], {
        name: "firm_tier"
    });
    return result;
}

export async function saveFirmWith(data) {
    let result = await authPOSTRequestWith(["firm"], data);
    return result;
}