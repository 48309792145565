import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fundingRounds, saveCompanyDataWith } from '../../api/admin';
import { ButtonLarge, ButtonSmall, LoadingView, Modal, NavBar } from '../base';
import { colorFor, dateDisplayFrom, daysSince } from '../formatting';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styles from './styles/Info.module.scss';

export class Info extends Component {
    constructor(props) {
        super(props);

        this.state = {
            info: {},
            saving: false
        };

        this.edit = this.edit.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.setState({ info: this.props.info });
    }

    edit(key, value) {
        let { info } = this.state;
        info[key] = value;
        this.setState({
            info: info
        });
    }

    async save() {
        this.setState({ saving: true });
        await saveCompanyDataWith(this.props.org, this.state.info);
        this.setState({ saving: false });
    }
    
    render() {
        return (
            <div>
                <InfoBar save={this.save} saving={this.state.saving} />
                <div className={styles.infoLayout}>
                    <InfoItem
                        content={this.state.info.summary}
                        edit={this.edit}
                        type="summary"
                        placeholder="Summary of the company"
                        title="Summary"
                    />
                    <InfoItem
                        content={this.state.info.notes}
                        edit={this.edit}
                        type="notes"
                        placeholder="Notes on this company useful for fundraising"
                        title="Notes"
                    />
                    <InfoItem
                        content={this.state.info.blurb}
                        edit={this.edit}
                        type="blurb"
                        placeholder="Blurb used for company intros"
                        title="Intro Blurb"
                    />
                </div>
            </div>
        );
    }
}

class InfoBar extends Component {
    render() {
        return (
            <div className={styles.bar}>
                <div className={styles.barText}>
                    <h3>Reminder</h3>
                    <p>All settings are global</p>
                </div>
                <ButtonLarge
                    color="yellow"
                    onClick={this.props.save}
                    loading={this.props.saving}
                    style={{width: 179.67}}
                    title="Save Settings"
                />
            </div>
        );
    }
}

class InfoItem extends Component {
    constructor() {
        super();

        this.editContent = this.editContent.bind(this);
    }

    editContent(e) {
        let value = e.target.value;
        this.props.edit(this.props.type, value);
    }

    render() {
        return (
            <div className={styles.item}>
                <h1>{this.props.title}</h1>
                <textarea onChange={this.editContent} placeholder={this.props.placeholder} value={this.props.content || ""}></textarea>
            </div>
        );
    }
}