import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCpSMNQ8BrYgBdEKU-BOfiv5M1cN8UH0iQ",
    authDomain: "orchard-91f4e.firebaseapp.com",
    projectId: "orchard-91f4e",
    storageBucket: "orchard-91f4e.appspot.com",
    messagingSenderId: "153348443875",
    appId: "1:153348443875:web:930c68acf2dc0dc4f97fd6",
    measurementId: "G-B4FLRY1ZVC"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const app = firebase.app();
export const auth = firebase.auth();
export const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
};