// Config.js
//
// This file presents a series of helpers and constants accessible by all API files
// Copyright 2021 Addison Leong

import axios from 'axios';
import { auth, providers } from '../config/firebase';
// import { LOGOUT } from '../actions/auth';

// const hostname = 'http://localhost:8081/api';
const hostname = !(process.env.REACT_APP_DEV === "TRUE") ? 'https://sbdtj7rq28.execute-api.us-west-2.amazonaws.com/prod/api' : 'https://w35myzsjuf.execute-api.us-west-2.amazonaws.com/dev/api';

function endpointFrom(path) {
    // Add sanitizing features here
    return hostname + "/" + (typeof(path) === "string" ? path : path.join("/"));
}

export async function getRequestWith(path, params={}) {
    let response = await axios({
        method: 'GET',
        url: endpointFrom(path),
        headers: {
            "Content-Type": "application/json"
        },
        params: params
    });
    if (response.status >= 300) return response.status;
    if (response.status === 200 && response.data === 'OK') return true;
    return response.data;
}

export async function authRequestWith(path, method, payload) {
    try {
        const token = await auth.currentUser.getIdToken();
        let params = {
            method: method,
            url: endpointFrom(path),
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            }
        };
        if (payload) {
            if (method.toLowerCase() === "get") {
                params["params"] = payload;
            } else {
                params["data"] = payload;
            }
        }
        let response = await axios(params);
        if (response.status >= 300) return response.status;
        if (response.status === 200 && response.data === 'OK') return true;
        return response.data;
    } catch(e) {
        return null;
    }
}

export async function authGETRequestWith(path, params={}) {
    try {
        let token = await auth.currentUser.getIdToken();
        let response = await axios({
            method: 'GET',
            url: endpointFrom(path),
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
            params: params
        });
        if (response.status >= 300) return response.status;
        if (response.status === 200 && response.data === 'OK') return true;
        return response.data;
    } catch (e) {
        // throw(e);
        return null; // Return a falsey value
    }
}

export async function authPOSTRequestWith(path, body={}) {
    try {
        let token = await auth.currentUser.getIdToken();
        let response = await axios({
            method: 'POST',
            url: endpointFrom(path),
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
            data: body
        });
        if (response.status >= 300) return response.status;
        if (response.status === 200 && response.data === 'OK') return true;
        return response.data;
    } catch (e) {
        // throw(e);
        return null; // Return a falsey value
    }
}

export async function authPUTRequestWith(path, body={}) {
    try {
        let token = await auth.currentUser.getIdToken();
        let response = await axios({
            method: 'PUT',
            url: endpointFrom(path),
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
            data: body
        });
        if (response.status >= 300) return response.status;
        if (response.status === 200 && response.data === 'OK') return true;
        return response.data;
    } catch (e) {
        // throw(e);
        return null; // Return a falsey value
    }
}
