import { authGETRequestWith, authPUTRequestWith } from "./config";

export async function savePartnerNamed(firstName, lastName, email, firm) {
    let success = await authPUTRequestWith(["partner"], {
        firm: firm,
        first_name: firstName,
        last_name: lastName,
        email: email
    });
    return success;
}

export async function getPartnersFrom(page, pageSize) {
    let success = await authGETRequestWith(["partner"], {
        page_size: pageSize,
        page: page
    });
    return success;
}