import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { prospectsFor, prospectStates, scoreFor, updateProspectWith } from '../api';
import { ButtonSmall, LoadingView, Modal } from './base';
import { colorFor, dateDisplayFrom } from './formatting';
import { ProspectModal } from './prospect';
import { RatingModal } from './rating';

import plus from './images/plus.svg';

import styles from './styles/Kanban.module.scss';
import { SearchModal } from './search';

const STATUS_NEW = 'New'; // TODO: Move these into a constants structure
const STATUS_INTRO_REQUEST = 'Intro Requested';
const STATUS_PASSED = "Passed";
const STATUS_TERM_SHEET = "Received Term Sheet";
const STATUS_FIRST_MEETING = "First Meeting";

export class Kanban extends Component {
    // Setup functions
    constructor() {
        super();

        this.state = {
            updating: false
        };
    }

    componentDidMount() {
        this.loadData();
        this.reloader = setInterval(() => {
            this.loadData();
        }, 10000);
    }

    componentWillUnmount() {
        if (this.reloader) clearInterval(this.reloader);
    }

    // Modal functions
    closeModal = () => {
        // this.loadData();
        this.setState({ modalData: null });
    }

    closeRating = () => {
        this.setState({ rating: false });
    }

    closeSearch = () => {
        this.loadData();
        this.setState({ searching: false });
    }

    openProspectWith = async (data) => {
        this.setState({ modalData: data, searching: false });
    }

    openRating = async (firm, ratingType) => {
        this.setState({ rating: true, ratingFirm: firm, ratingType: ratingType });
    }
    
    openSearch = async () => {
        this.setState({ searching: true });
    }

    // Column functions
    collapse = (index) => {
        let newStates = this.state.states;
        newStates[index]["collapsed"] ? newStates[index]["collapsed"] = !newStates[index]["collapsed"] : newStates[index]["collapsed"] = true;
        this.setState({ states: newStates });
    }

    // Data functions
    loadData = async () => {
        if (this.state.loading) this.setLoading(false);
        let prospects = await prospectsFor(this.props.fundingRound); // TODO: set this dynamic
        let { states } = this.state;
        if (!states) states = await prospectStates();
        if (this.state.loading) return;
        this.setState({ prospects: prospects, states: states });
    }

    move = (result) => {
        this.setLoading(true);
        let { destination, draggableId, source } = result;
        if (!destination) return;
        let { prospects, states } = this.state;
        let newState = parseInt(destination.droppableId);
        let movable = prospects.find(x => x.firm.id == draggableId);
        movable.firm.status = newState;
        this.setState({ prospects: prospects });
        // TODO: Add ordering
        let needsRating = [STATUS_PASSED, STATUS_TERM_SHEET].includes(states.find(x => x.id === newState).name);
        let needsMeetingInfo = [STATUS_FIRST_MEETING].includes(states.find(x => x.id === newState).name);
        updateProspectWith(movable.firm.prospect, "status", newState);
        if (needsRating) {
            let ratingType = states.find(x => x.id === newState).name === STATUS_TERM_SHEET ? "term_sheet" : "passed";
            this.openRating(movable.firm, ratingType);
        } else if (needsMeetingInfo) {
            this.openRating(movable.firm, "first_meeting");
        }
    }

    recalculateScore = async (firm, org, prospect) => {
        this.setLoading(true);
        let score = await scoreFor(firm, prospect, org, this.props.fundingRound);
        let { prospects } = this.state;
        let prospectMatch = prospects.find(x => x.firm.id == firm);
        prospectMatch.score = score.score;
        this.setState({ prospects: prospects });
    }
    
    updateConflict = (firm, value) => {
        let { prospects } = this.state;
        let prospect = prospects.find(x => x.firm.id == firm);
        prospect["has_conflict"] = value;
        this.setState({ prospects: prospects });
    }
    
    setIntroRequested = (prospect) => {
        let { prospects } = this.state;
        let prospectMatch = prospects.find(x => x.firm.prospect == prospect);
        prospectMatch.intro_request = true;
        this.setState({ prospects: prospects });
    }
    
    updateStatus = (prospect, id) => {
        let { prospects } = this.state;
        let prospectMatch = prospects.find(x => x.firm.prospect == prospect);
        prospectMatch.firm.status = id;
        this.setState({ prospects: prospects });
    }
    
    updateTag = (id, prospect, value, firm, fundingRound) => {
        let { prospects } = this.state;
        let prospectMatch = prospects.find(x => x.firm.prospect == prospect);
        let { org } = this.props;
        this.recalculateScore(firm, org, prospect, fundingRound);
        prospectMatch.tags.find(x => x.tag == id).value = value;
        this.setState({ prospects: prospects });
    }

    updateTermSheet = (firm, term, value) => {
        let { prospects } = this.state;
        let prospectMatch = prospects.find(x => x.firm.id == firm);
        prospectMatch.firm[term] = value;
        this.setState({ prospects: prospects });
    }
    
    kanbanStructureFromProspects = () => {
        if (!this.state.prospects) return null;
        return this.state.prospects.reduce(function(accumulator, item) {
            let status = item.firm.status;
            if (!(status.toString() in accumulator)) accumulator[status.toString()] = [];
            accumulator[status.toString()].push(item);
            accumulator[status.toString()].sort((a, b) => a.date_created > b.date_created);
            return accumulator;
        }, {});
    }

    setLoading = (value) => {
        this.setState({ loading: value });
    }

    render() {
        let prospects = this.kanbanStructureFromProspects();
        return this.state.states ? (
            <div className={styles.kanbanArea}>
                <DragDropContext onDragEnd={this.move}>
                    { this.state.states.map((x, index) =>
                        <KanbanColumn
                            key={x.name}
                            collapse={this.collapse}
                            collapsed={x.collapsed}
                            title={x.name}
                            id={x.id}
                            index={index}
                            items={prospects && x.id.toString() in prospects ? prospects[x.id.toString()] : null}
                            last={index === this.state.states.length - 1}
                            openProspect={this.openProspectWith}
                            first={index === 0}
                            openSearch={index === 0 ? this.openSearch : null}
                        />
                    )}
                </DragDropContext>
                <Modal closeModal={this.closeModal} visible={this.state.modalData != null}>
                    <ProspectModal
                        data={this.state.modalData}
                        org={this.props.org}
                        states={this.state.states}
                        updateConflict={this.updateConflict}
                        updateStatus={this.updateStatus}
                        updateTag={this.updateTag}
                        updateTermSheet={this.updateTermSheet}
                        setIntroRequested={this.setIntroRequested}
                    />
                </Modal>
                <Modal closeModal={this.closeRating} visible={this.state.rating}>
                    <RatingModal
                        close={this.closeRating}
                        firm={this.state.ratingFirm}
                        fundingRound={this.props.fundingRound}
                        ratingType={this.state.ratingType}
                        passStatus={(this.state.states.find(x => x.name === STATUS_PASSED)).id}
                    />
                </Modal>
                <Modal closeModal={this.closeSearch} visible={this.state.searching}>
                    <SearchModal
                        close={this.closeSearch}
                        fundingRound={this.props.fundingRound}
                        openExisting={this.openProspectWith}
                        states={this.state.states}
                    />
                </Modal>
            </div>
        ) : (
            <LoadingView />
        );
    }
}

class KanbanColumn extends Component {
    collapse = () => {
        if (this.props.items && this.props.items.length > 0) this.props.collapse(this.props.index);
    }

    render() {
        let collapsed = this.props.collapsed || !this.props.items || (this.props.items && this.props.items.length < 1);
        return (
            <div
                key={ this.props.id.toString() }
                className={!collapsed ? styles.kanbanColumn : styles.kanbanColumnCollapsed}
                style={this.props.last ? {minWidth: collapsed ? '83px' : '273px'} : {}}
            >
                <Droppable key={ this.props.id.toString() } droppableId={ this.props.id.toString() } type="PROSPECT">
                    {(provided, snapshot) => (
                        <div className={styles.kanbanColumnContainer}>
                            { collapsed ? (
                                <div className={styles.kanbanColumnCollapsedTitle} onClick={this.collapse}>
                                    <h1 className={styles.kanbanColumnTitle}>{ this.props.title }</h1>
                                    { this.props.items && this.props.items.length > 0 &&
                                        <KanbanColumnCounter count={this.props.items.length} />
                                    }
                                    { this.props.first &&
                                        <AddButton onClick={this.props.openSearch} />
                                    }
                                </div>
                            ) : (
                                <div className={styles.kanbanColumnTitleArea}>
                                    <h1 className={styles.kanbanColumnTitle} onClick={this.collapse}>{ this.props.title }</h1>
                                    { this.props.first &&
                                        <AddButton onClick={this.props.openSearch} />
                                    }
                                </div>
                            )}
                            <div className={styles.kanbanColumnDropArea}>
                                <div
                                    ref={provided.innerRef}
                                    style={collapsed ?
                                        { backgroundColor: snapshot.isDraggingOver ? 'white' : 'transparent', borderRadius: '3px', width: '35px' } : 
                                        { height: 'min-content', width: '228px' }
                                    }
                                    {...provided.droppableProps}
                                >
                                    { !this.props.collapsed && this.props.items && this.props.items.map((x, index) =>
                                        <KanbanItem
                                            data={x}
                                            firm={x.firm}
                                            name={x.firm.name}
                                            id={x.firm.id}
                                            index={index}
                                            key={x.firm.id}
                                            score={x.score}
                                            date_created={x.date_created}
                                            open={this.props.openProspect}
                                            status={this.props.id}
                                            has_conflict={x.has_conflict}
                                            intro_request={x.intro_request}
                                        />
                                    ) }
                                    {provided.placeholder}
                                </div>
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>
        );
    }
}

class KanbanColumnCounter extends Component {
    render() {
        return (
            <div className={styles.kanbanColumnCounter}>
                <p>{ this.props.count }</p>
            </div>
        );
    }
}

class KanbanItem extends Component {
    open = () => {
        this.props.open(this.props.data);
    }

    render() {
        let score = this.props.has_conflict ? 0 : this.props.score
        let color = colorFor(score);
        // let needsIntro = this.props.status === STATUS_NEW;
        let needsIntro = false;
        return (
            <Draggable key={this.props.id.toString()} draggableId={this.props.id.toString()} index={this.props.index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.kanbanItem}
                        onClick={this.open}
                    >
                        <div className={styles.kanbanItemHeader} style={{background: color.main}} />
                        <div className={styles.kanbanItemContent}>
                            { this.props.has_conflict &&
                                <h3 className={styles.kanbanItemConflict}>possible portfolio conflict</h3>
                            }
                            { (this.props.firm.status_name === STATUS_INTRO_REQUEST && !this.props.intro_request) &&
                                <h3 className={styles.kanbanItemConflict}>intro needs requesting</h3>
                            }
                            <h1>{ this.props.name }</h1>
                            { this.props.partner &&
                                <h2>{ this.props.partner }</h2>
                            }
                            { needsIntro &&
                                <ButtonSmall style={{ borderRadius: '3px', boxShadow: 'none', fontSize: '13px' }} title="Request Intro" />
                            }
                        </div>
                        <div className={styles.kanbanItemFooter}>
                            <span>{ this.props.firm.date_created && dateDisplayFrom(this.props.firm.date_created) }</span>
                            <div className={styles.kanbanItemScore}>
                                <CircularProgressbar
                                    value={ score }
                                    maxValue={1}
                                    strokeWidth={12}
                                    styles={{
                                        path: {
                                            stroke: color.light
                                        },
                                        trail: {
                                            stroke: score > 0 ? '#E8E8E8' : color.light
                                        }
                                    }}
                                />
                                <span>{ this.props.has_conflict ? "!" : (this.props.score * 10).toFixed(1) }</span>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    }
}

class AddButton extends Component {
    render() {
        return (
            <div className={styles.addButton} onClick={this.props.onClick}>
                <img src={plus} />
            </div>
        );
    }
}