import React, { Component } from 'react';

import { ButtonSmall } from './base';
import { dateDisplayFrom } from './formatting';

import styles from './styles/Notes.module.scss';
import { notesFor, saveNewNoteWith } from '../api/note';

export class Notes extends Component {
    constructor() {
        super();

        this.state = {
            newNote: "",
            notes: [],
            saving: false
        };

        this.cancel = this.cancel.bind(this);
        this.editNewNote = this.editNewNote.bind(this);
        this.loadNotes = this.loadNotes.bind(this);
        this.saveNote = this.saveNote.bind(this);
    }

    componentDidMount() {
        this.loadNotes();
    }
    
    cancel() {
        this.setState({
            newNote: ""
        });
    }
    
    editNewNote(e) {
        let value = e.target.value;
        this.setState({
            newNote: value
        });
    }
    
    async loadNotes() {
        let notes = await notesFor(this.props.prospect);
        this.setState({ notes: notes });
    }

    async saveNote() {
        let note = this.state.newNote;
        let notes = this.state.notes;
        notes.splice(0, 0, {
            content: note,
            date: new Date(),
            name: this.props.status.name
        });
        this.setState({
            newNote: "",
            notes: notes,
            saving: true
        });
        let result = await saveNewNoteWith(note, this.props.prospect, this.props.status.id);
        this.setState({
            saving: false
        });
    }

    render() {
        return (
            <div className={styles.notesArea}>
                <h1>Notes</h1>
                <textarea className={styles.noteInput} onChange={this.editNewNote} placeholder="Jot down a note..." value={this.state.newNote}></textarea>
                { this.state.newNote !== "" &&
                    <div className={styles.notesSave}>
                        <ButtonSmall color="gray" onClick={this.cancel} style={{ boxShadow: 'none', marginRight: '10px' }} title="Cancel" />
                        <ButtonSmall color={this.state.saving ? "gray" : "green"} onClick={this.saveNote} style={{ boxShadow: 'none' }} title="Save" />
                    </div>
                }
                <div className={styles.notes}>
                    {
                        this.state.notes && this.state.notes.map(
                            x => 
                            <div className={styles.note} key={x.id}>
                                <h2>{ x.name + " – " + dateDisplayFrom(new Date(x.date)) }</h2>
                                <p>{ x.content }</p>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}