import React, { Component } from 'react';

import { FormControl,InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import { firmStages, firmTiers } from '../api/firm';

import styles from './styles/Firm.module.scss';
import { ButtonLarge } from './base';

export class FirmModal extends Component {
    constructor() {
        super();

        this.state = {
            angel: "",
            domain: "",
            name: "",
            tier: "",
            tiers: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    createInvestor = () => {
        let { angel, domain, name, tier } = this.state;
        this.props.save({
            angel: angel,
            domain: domain,
            name: name,
            tier: tier
        });
    }
    
    filled = () => {
        let { angel, domain, name, tier } = this.state;
        return angel !== "" && domain !== "" && name !== "" && tier !== "";
    }
    
    loadData = async () => {
        let [stages, tiers] = await Promise.all([firmStages(), firmTiers()]);
        this.setState({
            stages: stages,
            tiers: tiers
        });
    }

    setAngel = (e) => { this.setState({ angel: e.target.value }); }
    setDomain = (e) => { this.setState({ domain: e.target.value }); }
    setName = (e) => { this.setState({ name: e.target.value }); }
    setTier = (e) => { this.setState({ tier: e.target.value }); }
    
    render() {
        return (
            <div className={styles.modal}>
                { this.props.name ? (
                    <h1 className={styles.title}>
                        Looks like <span className={styles.term}>{ this.props.name }</span> is not an investor in our database. <br/>
                        Please provide more information here:
                    </h1>
                ) : (
                    <h1 className={styles.title}>
                        Add a new <span className={styles.term}>firm/angel</span> to the database
                    </h1>
                )}
                <div className={styles.gridInputs}>
                    <FormControl className={styles.type} variant="outlined">
                        <InputLabel id="investor-type-label">Investor Type</InputLabel>
                        <Select labelId="investor-type-label" label="Investor Type" onChange={this.setAngel} required value={this.state.angel}>
                            <MenuItem disabled value="">Investor Type</MenuItem>
                            <MenuItem value={false}>Firm</MenuItem>
                            <MenuItem value={true}>Angel</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField className={styles.name} onChange={this.setName} label={ this.state.angel == "true" ? "Angel Name (e.g. Pejman Nozad)" : "Firm Name (e.g. Pear VC)" } type="text" value={this.state.name} variant="outlined" />
                    <TextField className={styles.domain} onChange={this.setDomain} label={ this.state.angel == "true" ? "Email Address" : "Firm Domain (e.g. pear.vc)" } type="text" value={this.state.domain} variant="outlined" />
                    <FormControl variant="outlined">
                        <InputLabel id="investor-tier-label">Investor Tier</InputLabel>
                        <Select className={styles.tier} labelId="investor-tier-label" label="Investor Tier" onChange={this.setTier} required value={this.state.tier}>
                            <MenuItem disabled value="">Investor Tier</MenuItem>
                            {
                                this.state.tiers.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className={styles.buttons}>
                    <ButtonLarge color="gray" onClick={this.props.cancel} title="Back to Search" />
                    <ButtonLarge color={ "green"} disabled={!this.filled()} onClick={this.createInvestor} title="Next" />
                </div>
            </div>
        );
    }
}