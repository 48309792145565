import { auth, providers } from '../config/firebase';
import useSWR from 'swr';

import { authGETRequestWith } from './config';

// export function signUp() {
//     const { data, error } = useSWR('/signup', signUpAsync);
//     return data;
// }

export function isLoggedIn() {
    const { data, error } = useSWR('/is-logged-in', isLoggedInAsync);
    return data;
}

export async function signUp() {
    try {
        const response = await auth.signInWithPopup(providers.googleProvider);
        const loggedIn = await isLoggedInAsync();
        return loggedIn;
    } catch (error) {
        console.log(error);
        return { error };
    }
};

export async function isLoggedInAsync() {
    const loginStatus = await authGETRequestWith('login');
    if (loginStatus === 401 || loginStatus === 500) {
        return false;
    } else {
        return loginStatus;
    }
}