/* Imports */
// React
import React, { Component } from 'react';

// Material UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// APIs
import { savePartnerNamed, searchFor } from '../api';

// UIs
import styles from './styles/Firm.module.scss';
import { ButtonLarge } from './base';

/* Class Declarations */
export class PartnerModal extends Component {
    render() {
        return (
            <div className={styles.partner}>
                <h1 className={styles.title}>Create a new contact</h1>
                <PartnerCreator close={this.props.cancel} firm={this.props.firm} />
            </div>
        );
    }
}

export class PartnerCreator extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            firstName: "",
            lastName: "",
            investor: null,
            filteredInvestors: []
        };
    }

    createPartner = async () => {
        this.setState({ loading: true });
        let { email, firstName, investor, lastName } = this.state;
        let firm = this.props.firm || investor["id"];
        if (email == "" || firstName == "" || lastName == "" || !firm) return;
        let partner = await savePartnerNamed(firstName, lastName, email, firm);
        this.setState({ loading: false });
        if (this.props.close) this.props.close(partner);
    }
    
    editEmail = (e) => { this.setState({ email: e.target.value }); }
    editFirstName = (e) => { this.setState({ firstName: e.target.value }); }
    editLastName = (e) => { this.setState({ lastName: e.target.value }); }

    filled = () => {
        let { email, firstName, investor, lastName } = this.state;
        if (!this.props.firm && !investor) return false;
        return email !== "" && firstName !== "" && lastName !== "";
    }

    render() {
        let { filteredInvestors, investor, loading } = this.state;
        return (
            <div>
                <div className={styles.inputs}>
                    { !this.props.firm &&
                        <Autocomplete
                            value={investor}
                            getOptionLabel={(option) => (option.name)}
                            onChange={(event, newValue) => {
                                this.setState({ investor: newValue });
                            }}
                            onInputChange={async (event, newInputValue) => {
                                let newOptions = await searchFor(newInputValue);
                                // if (newInputValue !== "") newOptions.push({
                                //     inputValue: newInputValue,
                                //     name: `Add "${newInputValue}"`
                                // });
                                this.setState({ filteredInvestors: newOptions });
                            }}
                            filterOptions={x => x}
                            options={filteredInvestors}
                            // getOptionSelected={}
                            renderOption={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} autoFocus label="Firm" variant="outlined" />
                            )}
                            // className={styles.createInput}
                            className={styles.firm}
                        />
                    }
                    <TextField autoFocus={this.props.firm} className={styles.domain} onChange={this.editEmail} label="Email" type="email" value={this.state.email} variant="outlined" />
                    <TextField autoCapitalize="sentence" className={styles.stage} onChange={this.editFirstName} label="First name" type="text" value={this.state.firstName} variant="outlined" />
                    <TextField autoCapitalize="sentence" className={styles.tier} onChange={this.editLastName} label="Last name" type="text" value={this.state.lastName} variant="outlined" />
                </div>
                <div className={styles.buttons}>
                    <ButtonLarge color="gray" onClick={this.props.close} title="Cancel" />
                    <ButtonLarge color="green" disabled={!this.filled()} loading={loading} onClick={this.createPartner} title="Add Partner" />
                </div>
            </div>
        );
    }
}