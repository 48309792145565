import { authGETRequestWith, authPOSTRequestWith, authPUTRequestWith } from './config';

export async function prospectsFor(fundingRound) {
    let data = await authGETRequestWith("prospects", {funding_round: fundingRound});
    return data;
}

export async function prospectStates() {
    let data = await authGETRequestWith("prospect-states");
    return data;
}

export async function prospectWith(id) {
    let data = await authGETRequestWith(["prospect", id]);
    return data;
}

export async function saveTagWith(id, prospect, value) {
    let success = await authPOSTRequestWith(["tag", "prospect"], {
        tag: id,
        prospect: prospect,
        value: value
    });
    return success;
}

export async function partnersFor(firm, admin=false) { // Move to firm
    let adminFlag = admin ? { admin: true } : {};
    let data = await authGETRequestWith(["firm", "partners", firm], adminFlag);
    return data;
}

export async function updateProspectWith(id, attribute, value) {
    let success = await authPOSTRequestWith(["prospect"], {
        prospect: id,
        key: attribute,
        value: value
    });
    return success;
}

export async function findProspectForFirmWith(id, fundingRound) {
    let data = await authGETRequestWith(["search", "prospects"], {
        firm: id,
        funding_round: fundingRound
    });
    return data;
}

export async function saveProspectForFirmWith(id, fundingRound, tags, status) {
    let success = await authPUTRequestWith(["prospect"], {
        firm: id,
        funding_round: fundingRound,
        tags: tags,
        status: status
    });
    return success;
}

export async function scoreFor(firm, asProspect, forOrg, withFundingRound) {
    let score = await authGETRequestWith(["prospect-score"], {
        firm: firm,
        org: forOrg,
        prospect: asProspect,
        funding_round: withFundingRound
    });
    return score;
}